import { IAddress } from './interfaces';
import nonAddressRoutes from './nonAddressRoutes';

const getAddressFromPathname = (pathname: string): IAddress => {
  const pathnameChunks = pathname
    .split('/')
    .filter(chunk => !nonAddressRoutes.includes(chunk));

  return { type: pathnameChunks[0], id: pathnameChunks[1] };
};

export default getAddressFromPathname;
