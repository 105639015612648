import * as React from 'react';
import { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import AppContext from '../helpers/AppContext';
import Index from './Index/Index';
import Item from './Item';

class View extends Component<any> {
  public static contextType = AppContext;

  public async componentDidMount() {
    this.context.actions.refreshViewData();
  }

  public render() {
    return (
      <section className="view">
        <AppContext.Consumer>
          {({ state }) =>
            state.isLoading ? (
              <div role="presentation" className="loading-indicator">
                Loading...
              </div>
            ) : (
              <Switch>
                <Route exact path="/:itemType/settings" component={Index} />
                <Route exact path="/:itemType" component={Index} />
                <Route path="/:itemType/:id" component={Item} />
              </Switch>
            )
          }
        </AppContext.Consumer>
      </section>
    );
  }
}

export default View;
