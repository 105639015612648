import * as React from 'react';
import { NavLink } from 'react-router-dom';
import singularize from '../helpers/singularize';

interface IProps {
  link: string;
  icon: string;
  label: string;
  isCollapsed?: boolean;
  addNewItem?: (...args: any[]) => any;
}

const SideMenuItem = ({
  link,
  icon,
  label,
  isCollapsed,
  addNewItem,
}: IProps) => (
  <li>
    <NavLink
      to={link}
      className={'side-menu-item'}
      title={isCollapsed ? label : ''}
      aria-label={isCollapsed ? label : undefined}
    >
      <i role="presentation" className={'menuItemIcon fa icon fa5 ' + icon} />
      <span>{label}</span>
      {addNewItem && (
        <button
          className="new-item"
          title={'New ' + singularize(label).toLowerCase()}
          aria-label={'New ' + singularize(label).toLowerCase()}
          onClick={e => {
            e.preventDefault();
            addNewItem();
          }}
        >
          <i role="presentation" className="icon fa5 fas fa-plus" />
        </button>
      )}
    </NavLink>
  </li>
);

export default SideMenuItem;
