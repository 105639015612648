import * as moment from 'moment';
import * as React from 'react';
import * as ReactDateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

type DateTimeProps = (
  | {
      initialValue: string;
      shouldRevertToInitVal: boolean;
      didRevertToInitVal: (...args: any[]) => any;
    }
  | {
      initialValue?: never;
      shouldRevertToInitVal?: never;
      didRevertToInitVal?: never;
    }) & {
  name: string;
  inputId?: string;
  type?: 'DATE_TIME' | 'DATE' | 'TIME';
  defaultValue: string;
  hasError?: boolean;
  setValue: (field: string, value: string | null) => void;
  setTouched?: (field: string, isTouched?: boolean) => any;
};

export default class DateTime extends React.Component<DateTimeProps> {
  public componentDidUpdate() {
    if (this.props.shouldRevertToInitVal) this.props.didRevertToInitVal();
  }

  public render() {
    const {
      initialValue,
      shouldRevertToInitVal,
      name,
      inputId,
      type,
      defaultValue,
      hasError,
      setValue,
      setTouched,
    } = this.props;

    return (
      <ReactDateTime
        dateFormat={[undefined, 'DATE_TIME', 'DATE'].includes(type)}
        timeFormat={[undefined, 'DATE_TIME', 'TIME'].includes(type)}
        className={
          type
            ? {
                DATE_TIME: undefined,
                DATE: 'date-only',
                TIME: 'time-only',
              }[type]
            : undefined
        }
        inputProps={{
          name,
          id: inputId,
          className: hasError ? 'has-error' : undefined,
          autoComplete: 'off',
          autoCorrect: 'off',
          autoCapitalize: 'off',
          spellCheck: false,
          'data-gramm': false,
        }}
        value={shouldRevertToInitVal ? moment(initialValue) : undefined}
        defaultValue={moment(defaultValue)}
        onBlur={setTouched ? () => setTouched(name) : undefined}
        onChange={newValue =>
          // If anything other than a moment object or empty string is provided,
          // return null so that we force a form validation error.
          setValue(
            name,
            moment.isMoment(newValue)
              ? newValue.toISOString()
              : newValue
              ? null
              : ''
          )
        }
      />
    );
  }
}
