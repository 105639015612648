export const statusOptions = {
  APPLIED: 'Applied',
  FOLLOWED_UP: 'Followed-up',
  INTERVIEW_SCHEDULED: 'Interview scheduled',
  INTERVIEWED: 'Interviewed',
  JOB_OFFERED: 'Job offered',
  JOB_DENIED: 'Job denied',
  OFFER_ACCEPTED: 'Offer accepted',
  OFFER_DECLINED: 'Offer declined',
  ARCHIVED: 'Archived',
};

export const priorityOptions = {
  HIGH: 'High',
  NORMAL: 'Normal',
  LOW: 'Low',
};

export const chatTypeOptions = {
  CONVERSATION: 'Conversation',
  CALL: 'Call',
  RECEIVED_VOICEMAIL: 'Received voicemail',
  EMAIL_THREAD: 'Email thread',
  SNAIL_MAIL: 'Snail mail',
};

export const jobTypeOptions = {
  SALARY: 'Salary',
  CONTRACT: 'Contract',
  HOURLY: 'Hourly',
};

export const jobHoursOptions = {
  FULL_TIME_: 'Full-time',
  PART_TIME: 'Part-time',
};
