import * as React from 'react';
import { Component } from 'react';
import CollapseSideMenuButton from './CollapseSideMenuButton';
import SideMenuItem from './SideMenuItem';
import NewJobModal from './Modal/NewJobModal';
import AppContext from '../helpers/AppContext';
import NewCompanyModal from './Modal/NewCompanyModal';
import NewContactModal from './Modal/NewContactModal';

interface IState {
  isCollapsed: boolean;
}

export default class SideMenu extends Component<any, IState> {
  public state: IState = {
    isCollapsed: false,
  };

  public toggleCollapsed = () => {
    this.setState(prevState => ({
      isCollapsed: !prevState.isCollapsed,
    }));
  };

  public render() {
    const { isCollapsed } = this.state;

    return (
      <nav id="side-menu" className={isCollapsed ? 'collapsed' : ''}>
        <AppContext.Consumer>
          {context => (
            <ul>
              <SideMenuItem
                link="/jobs"
                icon="fa-briefcase"
                label="Jobs"
                isCollapsed={isCollapsed}
                addNewItem={() => context.actions.setModal(<NewJobModal />)}
              />
              <SideMenuItem
                link="/companies"
                icon="fa-building"
                label="Companies"
                isCollapsed={isCollapsed}
                addNewItem={() => context.actions.setModal(<NewCompanyModal />)}
              />
              <SideMenuItem
                link="/contacts"
                icon="fa-users"
                label="Contacts"
                isCollapsed={isCollapsed}
                addNewItem={() => context.actions.setModal(<NewContactModal />)}
              />
            </ul>
          )}
        </AppContext.Consumer>

        <CollapseSideMenuButton
          isCollapsed={isCollapsed}
          toggleCollapsed={this.toggleCollapsed}
        />
      </nav>
    );
  }
}
