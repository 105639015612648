import { Component } from 'react';

interface IProps {
  signOut: () => void;
}

export default class SignOut extends Component<IProps> {
  public componentDidMount() {
    this.props.signOut();
  }

  public render() {
    return null;
  }
}
