import * as React from 'react';

type ButtonProps = (
  | { label: string; ariaLabel?: never }
  | { label?: never; ariaLabel: string }) &
  (
    | {
        // type prop is optional if onClick is provided.
        type?: 'submit' | 'reset' | 'button';
        onClick: (...args: any[]) => any;
      }
    | {
        // If type is "submit" or "reset", onClick is optional.
        type: 'submit' | 'reset';
        onClick?: (...args: any[]) => any;
      }) & {
    className?: string;
    dangerous?: boolean;
    disabled?: boolean;
    form?: string;
    icon?: 'plus';
    id?: string;
    pressed?: boolean;
    size?: 'small' | 'medium';
    stature?: 'primary' | 'secondary' | 'trivial';
    title?: string;
  };

const Button = ({
  ariaLabel,
  className,
  dangerous,
  disabled,
  form,
  icon,
  id,
  label,
  onClick,
  pressed,
  size,
  stature,
  title,
  type,
}: ButtonProps) => {
  const iconName = icon ? { plus: 'fa-plus' }[icon] : '';

  let isPressed;
  if (pressed !== undefined) isPressed = pressed !== false;

  const computedClass = `${className || ''} ${size || 'medium'} ${
    isPressed ? 'pressed' : ''
  } ${
    dangerous !== undefined && dangerous !== false ? 'dangerous' : ''
  } ${stature || 'secondary'} ${iconName ? 'has-icon' : ''}`
    .replace(/  +/g, ' ')
    .trim();

  const buttonProps = {
    id,
    className: computedClass,
    disabled: disabled !== undefined && disabled !== false,
    onClick,
    form,
    type: type || 'button',
    title,
  };

  return (
    <button {...buttonProps} aria-label={ariaLabel} aria-pressed={isPressed}>
      {iconName && (
        <i role="presentation" className={'icon fa fa5 ' + iconName} />
      )}
      {label}
    </button>
  );
};

export default Button;
