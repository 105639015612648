import * as React from 'react';
import AppContext from '../../helpers/AppContext';
import Button from '../Button';
import Modal from './Modal';

const SettingsModal = () => (
  <AppContext.Consumer>
    {({ actions, state }) => (
      <Modal
        title="Settings"
        beforeClose={() =>
          actions.pushHistory(
            state.location.pathname.replace(/\/settings/gi, '')
          )
        }
      >
        <>
          <div className="row medium-margin-bottom">
            <label htmlFor="account-email">Email</label>
            <input
              autoFocus
              type="text"
              id="account-email"
              name="account-email"
              value="eric@employamp.com"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck={false}
              data-gramm={false}
            />
          </div>

          <div className="row">
            <Button
              label="Sign out everywhere else"
              onClick={() => {
                throw new Error('Button onClick handler not implemented');
              }}
            />
            <Button
              className="flush-right"
              dangerous
              label="Delete account"
              onClick={() => {
                throw new Error('Button onClick handler not implemented');
              }}
            />
          </div>
        </>
      </Modal>
    )}
  </AppContext.Consumer>
);

export default SettingsModal;
