import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';
import AppContext from '../../helpers/AppContext';
import { updateData } from '../../helpers/crudData';
import {
  emailSchema,
  linkSchema,
  phoneSchema,
} from '../../helpers/validationSchemas';
import AddressField from '../AddressField';
import Button from '../Button';
import DateTime from '../DateTime';
import FieldArrayItem from '../FieldArrayItem';
import ItemChainLink from '../ItemChainLink';
import ItemSelect from '../ItemSelect';
import FieldArrayItemModal from '../Modal/FieldArrayItemModal';
import WarnOfUnsavedChanges from '../WarnOfUnsavedChanges';

interface IProps {
  deleteItem: () => Promise<void>;
  refresh: (...args: any[]) => any;
}

interface IState {
  dateTimeShouldRevertToInitVal: boolean;
}

export default class ContactSidebar extends React.Component<IProps, IState> {
  public static contextType = AppContext;

  public state: IState = {
    dateTimeShouldRevertToInitVal: false,
  };

  public render() {
    // Use destructuring and a self-calling function to pick out specific
    // properties from the primary data to use as Formik's initial values.
    const initialVals = (({
      _id,
      name,
      jobTitle,
      primaryCompany,
      saved,
      emails,
      phones,
      links,
      address,
      notes,
    }) => ({
      _id,
      name,
      jobTitle,
      primaryCompany,
      saved,
      emails,
      phones,
      links,
      address,
      notes,
    }))(this.context.state.data.primary);

    const validationSchema = Yup.object().shape({
      name: Yup.object().shape({
        title: Yup.string(),
        first: Yup.string().required(),
        middle: Yup.string(),
        last: Yup.string().required(),
        suffix: Yup.string(),
      }),
      jobTitle: Yup.string(),
      primaryCompany: Yup.string().nullable(),
      saved: Yup.date()
        .typeError('Saved date must be in a valid format.')
        .required('Please indicate the date saved.'),
      emails: Yup.array().of(emailSchema),
      phones: Yup.array().of(phoneSchema),
      links: Yup.array().of(linkSchema),
      address: Yup.string(),
      notes: Yup.string(),
    });

    return (
      <section className="item-sidebar contact">
        <AppContext.Consumer>
          {({ actions: { setModal } }) => (
            <Formik
              initialValues={initialVals}
              validationSchema={validationSchema}
              onSubmit={(values, formActions) => {
                updateData({ type: 'contacts', id: values._id }, values)
                  .then(this.props.refresh)
                  .then(formActions.resetForm)
                  .then(() => formActions.setSubmitting(false));
                this.setState({ dateTimeShouldRevertToInitVal: true });
              }}
              onReset={() =>
                this.setState({ dateTimeShouldRevertToInitVal: true })
              }
              render={({
                errors,
                touched,
                isSubmitting,
                handleChange,
                handleBlur,
                values,
                setFieldValue,
                setFieldTouched,
                dirty,
                initialValues,
                handleReset,
              }) => {
                const firstNameShouldShowError =
                  errors.name &&
                  (errors.name as any).first &&
                  touched.name &&
                  (touched.name as any).first;
                const lastNameShouldShowError =
                  errors.name &&
                  (errors.name as any).last &&
                  touched.name &&
                  (touched.name as any).last;

                return (
                  <Form>
                    <WarnOfUnsavedChanges when={dirty} />
                    <div className="row medium-margin-bottom">
                      <Field
                        type="text"
                        name="name.first"
                        autoComplete="off"
                        placeholder="First name"
                        aria-label="First name"
                        className={
                          'title name' +
                          (firstNameShouldShowError ? ' has-error' : '')
                        }
                      />
                      <Field
                        type="text"
                        name="name.last"
                        autoComplete="off"
                        placeholder="Last name"
                        aria-label="Last name"
                        className={
                          'title name' +
                          (lastNameShouldShowError ? ' has-error' : '')
                        }
                      />

                      <Button
                        type="submit"
                        label="Save"
                        className="flush-right"
                        stature="primary"
                        disabled={isSubmitting || !dirty}
                      />
                      <Button
                        label="Cancel"
                        onClick={handleReset}
                        disabled={isSubmitting || !dirty}
                      />
                    </div>
                    {firstNameShouldShowError || lastNameShouldShowError ? (
                      <div className="form-validation-error">
                        {firstNameShouldShowError && lastNameShouldShowError
                          ? 'Both the first and last names are required.'
                          : firstNameShouldShowError
                          ? 'A first name is required.'
                          : 'A last name is required.'}
                      </div>
                    ) : null}

                    <div className="row medium-margin-bottom">
                      <label htmlFor="jobTitle">Job title</label>
                      <Field
                        type="text"
                        name="jobTitle"
                        id="jobTitle"
                        className={
                          errors.jobTitle && touched.jobTitle ? 'has-error' : ''
                        }
                      />
                    </div>
                    <ErrorMessage name="jobTitle">
                      {msg => (
                        <div className="form-validation-error">{msg}</div>
                      )}
                    </ErrorMessage>

                    <div className="row medium-margin-bottom">
                      <label htmlFor="primaryCompany">Company</label>
                      <ItemSelect
                        id="primaryCompany"
                        value={values.primaryCompany}
                        placeholder="Select..."
                        type="companies"
                        setValue={(value?: string) =>
                          setFieldValue('primaryCompany', value || null)
                        }
                        hasError={
                          !!(errors.primaryCompany && touched.primaryCompany)
                        }
                        onBlur={handleBlur}
                      />
                      <ItemChainLink
                        item={{
                          type: 'companies',
                          id: initialValues.primaryCompany,
                        }}
                        visible={
                          initialValues.primaryCompany &&
                          values.primaryCompany === initialValues.primaryCompany
                        }
                      />
                    </div>
                    <ErrorMessage name="primaryCompany">
                      {msg => (
                        <div className="form-validation-error">{msg}</div>
                      )}
                    </ErrorMessage>

                    <div className="row medium-margin-bottom">
                      <label htmlFor="saved">Saved</label>
                      <DateTime
                        name="saved"
                        inputId="saved"
                        type="DATE"
                        initialValue={initialValues.saved}
                        defaultValue={values.saved}
                        shouldRevertToInitVal={
                          this.state.dateTimeShouldRevertToInitVal
                        }
                        didRevertToInitVal={() =>
                          this.setState({
                            dateTimeShouldRevertToInitVal: false,
                          })
                        }
                        hasError={!!(errors.saved && touched.saved)}
                        setValue={setFieldValue}
                        setTouched={setFieldTouched}
                      />
                      <Button
                        label="Delete contact"
                        dangerous
                        size="small"
                        onClick={this.props.deleteItem}
                      />
                    </div>
                    <ErrorMessage name="saved">
                      {msg => (
                        <div className="form-validation-error">{msg}</div>
                      )}
                    </ErrorMessage>

                    <div className="row small-margin-bottom">
                      <label>Links</label>
                    </div>
                    <FieldArray
                      name="links"
                      render={arrayHelpers => (
                        <div className="field-array-items medium-margin-bottom">
                          {values.links.map((link: any, i: number) => (
                            <FieldArrayItem
                              key={i}
                              label={link.label}
                              href={link.value}
                              value={link.value}
                              valueType="link"
                              valueFieldLabel="URL"
                              valueNameForHeading="link"
                              setValue={(value: string, label?: string) => {
                                setFieldValue(`links[${i}].value`, value);
                                setFieldValue(`links[${i}].label`, label);
                              }}
                              deleteItem={() => arrayHelpers.remove(i)}
                              validationSchema={linkSchema}
                            />
                          ))}

                          <Button
                            size="small"
                            stature="trivial"
                            icon="plus"
                            label="Add link"
                            className="add"
                            onClick={() =>
                              setModal(
                                <FieldArrayItemModal
                                  valueType="link"
                                  valueFieldLabel="URL"
                                  valueNameForHeading="link"
                                  validationSchema={linkSchema}
                                  isNew
                                  addItem={(value: string, label?: string) =>
                                    arrayHelpers.insert(values.links.length, {
                                      value,
                                      label,
                                    })
                                  }
                                />
                              )
                            }
                          />
                        </div>
                      )}
                    />

                    <div className="row small-margin-bottom">
                      <label>Emails</label>
                    </div>
                    <FieldArray
                      name="emails"
                      render={arrayHelpers => (
                        <div className="field-array-items medium-margin-bottom">
                          {values.emails.map((email: any, i: number) => (
                            <FieldArrayItem
                              key={i}
                              label={email.label}
                              href={'mailto:' + email.value}
                              value={email.value}
                              valueType="email"
                              valueFieldLabel="Email"
                              valueNameForHeading="email"
                              setValue={(value: string, label?: string) => {
                                setFieldValue(`emails[${i}].value`, value);
                                setFieldValue(`emails[${i}].label`, label);
                              }}
                              deleteItem={() => arrayHelpers.remove(i)}
                              validationSchema={emailSchema}
                            />
                          ))}

                          <Button
                            size="small"
                            stature="trivial"
                            icon="plus"
                            label="Add email"
                            className="add"
                            onClick={() =>
                              setModal(
                                <FieldArrayItemModal
                                  valueType="email"
                                  valueFieldLabel="Email"
                                  valueNameForHeading="email"
                                  validationSchema={emailSchema}
                                  isNew
                                  addItem={(value: string, label?: string) =>
                                    arrayHelpers.insert(values.emails.length, {
                                      value,
                                      label,
                                    })
                                  }
                                />
                              )
                            }
                          />
                        </div>
                      )}
                    />

                    <div className="row small-margin-bottom">
                      <label>Phones</label>
                    </div>
                    <FieldArray
                      name="phones"
                      render={arrayHelpers => (
                        <div className="field-array-items medium-margin-bottom">
                          {values.phones.map((phone: any, i: number) => (
                            <FieldArrayItem
                              key={i}
                              label={phone.label}
                              href={'tel:' + phone.value}
                              value={phone.value}
                              valueType="phone"
                              valueFieldLabel="Phone"
                              valueNameForHeading="phone"
                              setValue={(value: string, label?: string) => {
                                setFieldValue(`phones[${i}].value`, value);
                                setFieldValue(`phones[${i}].label`, label);
                              }}
                              deleteItem={() => arrayHelpers.remove(i)}
                              validationSchema={phoneSchema}
                            />
                          ))}

                          <Button
                            size="small"
                            stature="trivial"
                            icon="plus"
                            label="Add phone"
                            className="add"
                            onClick={() =>
                              setModal(
                                <FieldArrayItemModal
                                  valueType="phone"
                                  valueFieldLabel="Phone"
                                  valueNameForHeading="phone"
                                  validationSchema={phoneSchema}
                                  isNew
                                  addItem={(value: string, label?: string) =>
                                    arrayHelpers.insert(values.phones.length, {
                                      value,
                                      label,
                                    })
                                  }
                                />
                              )
                            }
                          />
                        </div>
                      )}
                    />

                    <div className="row small-margin-bottom">
                      <label htmlFor="address">Address</label>
                    </div>
                    <div className="row medium-margin-bottom">
                      <AddressField
                        value={values.address}
                        setValue={(value: string) =>
                          setFieldValue('address', value)
                        }
                        id="address"
                        placeholder="Type..."
                        hasError={!!(errors.address && touched.address)}
                        onBlur={handleBlur}
                      />
                    </div>
                    <ErrorMessage name="address">
                      {msg => (
                        <div className="form-validation-error">{msg}</div>
                      )}
                    </ErrorMessage>

                    <div className="row small-margin-bottom">
                      <label htmlFor="notes">Notes</label>
                    </div>
                    <textarea
                      id="notes"
                      className="medium-margin-bottom"
                      name="notes"
                      value={values.notes}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage name="notes">
                      {msg => (
                        <div className="form-validation-error">{msg}</div>
                      )}
                    </ErrorMessage>
                  </Form>
                );
              }}
            />
          )}
        </AppContext.Consumer>
      </section>
    );
  }
}
