import { Location } from 'history';
import * as React from 'react';
import { IAppState } from '../App';

const getNoProviderErr = (contextType: string) =>
  new Error(`No ${contextType} provider found`);

const AppContext = React.createContext({
  actions: {
    pushHistory: (pathname: string): void => {
      throw getNoProviderErr('AppContext');
    },
    setAppState: (state: Partial<IAppState>): void => {
      throw getNoProviderErr('AppContext');
    },
    setModal: (modal: JSX.Element | null): void => {
      throw getNoProviderErr('AppContext');
    },
    setQSParams: (qsParams: any): void => {
      throw getNoProviderErr('AppContext');
    },
    setAllQSParams: (qsParams: any): void => {
      throw getNoProviderErr('AppContext');
    },
    refreshViewData: (): void => {
      throw getNoProviderErr('AppContext');
    },
    deleteEntryFromCurrentJob: (id: string): Promise<any> => {
      throw getNoProviderErr('AppContext');
    },
    addEntryToCurrentJob: (newEntry: any): Promise<any> => {
      throw getNoProviderErr('AppContext');
    },
    updateEntryInCurrentJob: (updatedEntry: any): Promise<any> => {
      throw getNoProviderErr('AppContext');
    },
  },
  state: {
    data: {
      primary: null as any,
      titles: {} as any,
      jobs: [] as any[],
      entries: [] as any[],
      companies: [] as any[],
      contacts: [] as any[],
    },
    isLoading: true,
    error: null as Error | null,
    indexLayout: 'TABLE',
    address: { type: '' },
    match: {},
    location: {} as Location<{}>,
    history: {},
    instancesOfUnsavedChanges: 0,
  },
});

export default AppContext;
