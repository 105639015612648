const copyToClipboard = (str: string) => {
  // Adapted from https://30secondsofcode.org/browser#copytoclipboard
  // Original code licensed under the Creative Commons Zero v1.0 Universal

  // Append a hidden textarea with a value of the text we want to copy.
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.setAttribute('aria-hidden', 'true');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  el.style.opacity = '0';
  document.body.appendChild(el);

  // Keep track of any selections already made.
  const selected =
    document.getSelection() &&
    (document.getSelection() as Selection).rangeCount > 0
      ? (document.getSelection() as Selection).getRangeAt(0)
      : false;

  // Select and copy the textarea's value.
  el.select();
  document.execCommand('copy');

  // Remove the textarea.
  document.body.removeChild(el);

  // Restore any previous selections that we stored.
  if (selected) {
    (document.getSelection() as Selection).removeAllRanges();
    (document.getSelection() as Selection).addRange(selected);
  }
};

export default copyToClipboard;
