import React from 'react';
import AppContext from '../helpers/AppContext';

interface IProps {
  when: boolean;
}

export default class WarnOfUnsavedChanges extends React.Component<IProps> {
  public static contextType = AppContext;

  public componentDidMount() {
    this.context.actions.setAppState({
      instancesOfUnsavedChanges:
        this.context.state.instancesOfUnsavedChanges +
        (this.props.when ? 1 : 0),
    });
  }

  public componentDidUpdate(prevProps: IProps) {
    if (prevProps.when !== this.props.when) {
      this.context.actions.setAppState({
        instancesOfUnsavedChanges:
          this.context.state.instancesOfUnsavedChanges +
          (this.props.when ? 1 : -1),
      });
    }
  }

  public componentWillUnmount() {
    this.context.actions.setAppState({
      instancesOfUnsavedChanges:
        this.context.state.instancesOfUnsavedChanges +
        (this.props.when ? -1 : 0),
    });
  }

  public render = () => null;
}
