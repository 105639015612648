import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as React from 'react';
import Button from '../Button';
import { IValueMetaAndItemSchema } from '../FieldArrayItem';
import UrlField from '../UrlField';
import Modal from './Modal';

interface AddingProps {
  setValue?: never;
  deleteItem?: never;
  value?: never;
  label?: never;
  isNew: true;
  addItem: (value: string, label?: string) => void;
}

interface EditingProps {
  setValue: (value: string, label?: string) => void;
  deleteItem: () => void;
  value: string;
  label?: string;
  isNew?: false;
  addItem?: never;
}

type FieldArrayItemModalProps = (AddingProps | EditingProps) &
  IValueMetaAndItemSchema;

const FieldArrayItemModal = ({
  setValue,
  deleteItem,
  valueType,
  valueFieldLabel,
  valueNameForHeading,
  value,
  label,
  isNew,
  addItem,
  validationSchema,
}: FieldArrayItemModalProps) => (
  <Modal
    title={(isNew ? 'Add ' : 'Edit ') + valueNameForHeading}
    className={'field-array-item-modal ' + valueType}
  >
    {(closeModal: () => void) => (
      <Formik
        initialValues={isNew ? { value: '', label: '' } : { value, label }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          if (isNew && addItem) addItem(values.value || '', values.label);
          else if (setValue) setValue(values.value || '', values.label);

          actions.setSubmitting(false);
          closeModal();
        }}
        render={({
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          values,
          submitForm,
          setFieldTouched,
          setFieldValue,
        }) => (
          <Form>
            <div className="row medium-margin-bottom">
              <label htmlFor="label">Label</label>
              <Field autoFocus type="text" name="label" id="label" />
            </div>

            <div className="row medium-margin-bottom">
              <label htmlFor="value">{valueFieldLabel}</label>
              {(() => {
                const commonProps = {
                  name: 'value',
                  id: 'value',
                  className: errors.value && touched.value ? 'has-error' : '',
                };

                switch (valueType) {
                  case 'link':
                    return (
                      <UrlField
                        {...commonProps}
                        value={values.value || ''}
                        placeholder="http://example.com"
                        onChange={handleChange}
                        onFocus={
                          // When the user is focused on the input, we never
                          // want to show a validation error.
                          () => setFieldTouched('value', false)
                        }
                        onBlur={(e: React.FocusEvent) => {
                          // Upon blur, we always want to show any validation
                          // errors.
                          setFieldTouched('value', true);
                          handleBlur(e);
                        }}
                        setValue={(newValue: string) =>
                          setFieldValue('value', newValue)
                        }
                      />
                    );
                  case 'email':
                    return (
                      <Field
                        {...commonProps}
                        type="email"
                        placeholder="name@example.com"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck={false}
                        data-gramm={false}
                      />
                    );
                  case 'phone':
                    return (
                      <Field
                        {...commonProps}
                        type="tel"
                        placeholder="(555) 555-1234"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck={false}
                        data-gramm={false}
                      />
                    );
                }
              })()}
            </div>
            <ErrorMessage name="value">
              {msg => <div className="form-validation-error">{msg}</div>}
            </ErrorMessage>

            <div className="row">
              <Button
                type="submit"
                stature="primary"
                label="Done"
                disabled={isSubmitting}
                onClick={submitForm}
              />
              {!isNew && (
                <Button
                  dangerous
                  label="Delete"
                  disabled={isSubmitting}
                  className="flush-right"
                  onClick={() => {
                    if (deleteItem) deleteItem();
                    closeModal();
                  }}
                />
              )}
            </div>
          </Form>
        )}
      />
    )}
  </Modal>
);

export default FieldArrayItemModal;
