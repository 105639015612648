import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import AppContext from '../helpers/AppContext';
import { readData } from '../helpers/crudData';
import { ISelectOption } from '../helpers/interfaces';

interface IProps {
  className?: string;
  hasError?: boolean;
  id?: string;
  loadOptionsOnMenuOpen?: boolean;
  onBlur?: any;
  placeholder?: string;
  setValue: (value?: string) => void;
  type: 'jobs' | 'entries' | 'companies' | 'contacts';
  value?: string;
  valuesToFilter?: string[];
}

const ItemSelect = ({
  className,
  hasError,
  id,
  loadOptionsOnMenuOpen,
  onBlur,
  placeholder,
  setValue,
  type,
  value,
  valuesToFilter,
}: IProps) => {
  const titles = useContext(AppContext).state.data.titles;
  const [options, setOptions] = useState(
    value
      ? [
          {
            label: titles[value],
            value,
          },
        ]
      : []
  );

  const loadOptions = async (): Promise<void> => {
    const items: any[] = await readData({ type }, ['titleOnly=true']);
    const fetchedOptions: ISelectOption[] = items.map(item => ({
      value: item._id,
      label: item.title,
    }));

    setOptions(fetchedOptions);
  };

  if (!loadOptionsOnMenuOpen)
    useEffect(() => {
      loadOptions();
    }, []);

  const getFilteredOptions = (): ISelectOption[] => {
    if (valuesToFilter && valuesToFilter.length > 0)
      return options.filter(option => !valuesToFilter.includes(option.value));

    return options;
  };

  const handleChange = (selectedOption: ValueType<ISelectOption>) => {
    if (!selectedOption || Array.isArray(selectedOption)) {
      setValue(undefined);

      return;
    }

    setValue(selectedOption.value);
  };

  return (
    <Select
      className={'single-dropdown' + (className ? ' ' + className : '')}
      classNamePrefix={hasError ? 'has-error' : undefined}
      inputId={id}
      isClearable={true}
      onBlur={onBlur}
      onChange={handleChange}
      onMenuOpen={loadOptionsOnMenuOpen ? loadOptions : () => undefined}
      options={getFilteredOptions()}
      placeholder={placeholder || ''}
      value={value ? options.find(option => option.value === value) : null}
    />
  );
};

export default ItemSelect;
