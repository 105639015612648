import * as React from 'react';
import AppContext from '../../helpers/AppContext';
import Button from '../Button';
import NewCompanyModal from '../Modal/NewCompanyModal';
import NewContactModal from '../Modal/NewContactModal';
import NewJobModal from '../Modal/NewJobModal';

interface IProps {
  selectedRowCount: number;
  deleteSelectedItems: () => void;
}

const IndexMenu = ({ selectedRowCount, deleteSelectedItems }: IProps) => (
  <AppContext.Consumer>
    {context => (
      <div id="index-menu">
        <Button
          label="New"
          stature="primary"
          icon="plus"
          onClick={() => {
            switch (context.state.address.type) {
              case 'jobs':
                context.actions.setModal(<NewJobModal />);
                break;
              case 'companies':
                context.actions.setModal(<NewCompanyModal />);
                break;
              case 'contacts':
                context.actions.setModal(<NewContactModal />);
                break;
              default:
                throw new Error('not implemented');
            }
          }}
        />
        <Button
          label={
            selectedRowCount < 1
              ? 'Delete selected'
              : `Delete selected (${selectedRowCount})`
          }
          disabled={selectedRowCount < 1}
          dangerous
          onClick={deleteSelectedItems}
        />
      </div>
    )}
  </AppContext.Consumer>
);

export default IndexMenu;
