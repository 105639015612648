import * as React from 'react';

const HelpButton = () => (
  <a
    href="https://employamp.com/support"
    target="_blank"
    id="help-button"
    className="round-nav-button header-button"
    title="Get support or send feedback"
    aria-label="Help"
  >
    <span role="presentation">?</span>
  </a>
);

export default HelpButton;
