import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as moment from 'moment';
import * as React from 'react';
import { Component } from 'react';
import * as Yup from 'yup';
import AppContext from '../../helpers/AppContext';
import { chatTypeOptions } from '../../helpers/dictionaries';
import JobEntryProps from '../../helpers/JobEntryProps';
import Button from '../Button';
import DateTime from '../DateTime';
import EntryIcon from './EntryIcon';
import WarnOfUnsavedChanges from '../WarnOfUnsavedChanges';

interface IState {
  dateTimeShouldRevertToInitVal: boolean;
}

export default class ChatLogEntry extends Component<JobEntryProps, IState> {
  public state: IState = {
    dateTimeShouldRevertToInitVal: false,
  };

  public render() {
    const { isNew, depressEntryComposer, data } = this.props;

    return (
      <article className={'chat-log' + (!isNew ? ' entry' : '')}>
        <AppContext.Consumer>
          {({ actions }) => (
            <Formik
              initialValues={
                data || {
                  type: 'CHAT_LOG',
                  title: '',
                  chatType: 'CONVERSATION',
                  sort: moment().toISOString(),
                  end: moment()
                    .add(1, 'hours')
                    .toISOString(),
                  content: '',
                }
              }
              validationSchema={Yup.object().shape({
                type: Yup.string()
                  .oneOf(['CHAT_LOG'])
                  .required(),
                title: Yup.string().required('Please include a title.'),
                sort: Yup.date()
                  .typeError('Start date must be in a valid format.')
                  .required('Please indicate the start date.'),
                end: Yup.date()
                  .typeError('End date must be in a valid format.')
                  .required('Please indicate the end date.'),
                chatType: Yup.string()
                  .oneOf(
                    Object.keys(chatTypeOptions),
                    'The chat type must be a valid chat type.'
                  )
                  .required('Please choose a chat type.'),
                content: Yup.string(),
              })}
              onSubmit={async (values, formActions) => {
                if (isNew && depressEntryComposer) {
                  await actions.addEntryToCurrentJob(values);

                  await actions.refreshViewData();
                  depressEntryComposer();
                } else {
                  await actions.updateEntryInCurrentJob(values);
                  await actions.refreshViewData();

                  formActions.resetForm();
                  this.setState({ dateTimeShouldRevertToInitVal: true });
                }
              }}
              onReset={() =>
                this.setState({ dateTimeShouldRevertToInitVal: true })
              }
              render={({
                errors,
                touched,
                isSubmitting,
                values,
                setFieldValue,
                setFieldTouched,
                dirty,
                initialValues,
                handleReset,
              }) => (
                <Form>
                  <WarnOfUnsavedChanges when={dirty} />
                  <div className="row medium-margin-bottom">
                    {!isNew && <EntryIcon type="chatLog" />}

                    <Field
                      name="title"
                      placeholder="Title"
                      className="title"
                      autoFocus={isNew}
                    />

                    {isNew ? (
                      <>
                        <Button
                          type="submit"
                          label="Create"
                          size="medium"
                          stature="primary"
                          className="flush-right"
                          disabled={isSubmitting}
                        />
                        <Button
                          onClick={handleReset}
                          label="Clear"
                          disabled={isSubmitting || !dirty}
                        />
                      </>
                    ) : (
                      <>
                        <Button
                          type="submit"
                          label="Save"
                          size="small"
                          stature="primary"
                          className="flush-right"
                          disabled={isSubmitting || !dirty}
                        />
                        <Button
                          onClick={handleReset}
                          label="Cancel"
                          size="small"
                          disabled={isSubmitting || !dirty}
                        />
                      </>
                    )}
                  </div>
                  <ErrorMessage name="title">
                    {msg => <div className="form-validation-error">{msg}</div>}
                  </ErrorMessage>

                  <div className="row medium-margin-bottom">
                    <Field name="chatType" id="chatType" component="select">
                      {Object.entries(chatTypeOptions).map(([key, value]) => (
                        <option key={key} value={key}>
                          {value}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <ErrorMessage name="chatType">
                    {msg => <div className="form-validation-error">{msg}</div>}
                  </ErrorMessage>

                  <div className="row medium-margin-bottom">
                    <label htmlFor="sort">Start</label>
                    <DateTime
                      name="sort"
                      inputId="sort"
                      initialValue={initialValues.sort}
                      defaultValue={values.sort}
                      shouldRevertToInitVal={
                        this.state.dateTimeShouldRevertToInitVal
                      }
                      didRevertToInitVal={() =>
                        this.setState({
                          dateTimeShouldRevertToInitVal: false,
                        })
                      }
                      hasError={!!(errors.sort && touched.sort)}
                      setValue={setFieldValue}
                      setTouched={setFieldTouched}
                    />

                    <label htmlFor="end">End</label>
                    <DateTime
                      name="end"
                      inputId="end"
                      initialValue={initialValues.end}
                      defaultValue={values.end}
                      shouldRevertToInitVal={
                        this.state.dateTimeShouldRevertToInitVal
                      }
                      didRevertToInitVal={() =>
                        this.setState({
                          dateTimeShouldRevertToInitVal: false,
                        })
                      }
                      hasError={!!(errors.end && touched.end)}
                      setValue={setFieldValue}
                      setTouched={setFieldTouched}
                    />
                  </div>
                  <ErrorMessage name="sort">
                    {msg => <div className="form-validation-error">{msg}</div>}
                  </ErrorMessage>
                  <ErrorMessage name="end">
                    {msg => <div className="form-validation-error">{msg}</div>}
                  </ErrorMessage>

                  <div className="row medium-margin-bottom">
                    <Field
                      name="content"
                      component="textarea"
                      placeholder={isNew || dirty ? 'Details...' : undefined}
                    />
                  </div>
                  <ErrorMessage name="content">
                    {msg => <div className="form-validation-error">{msg}</div>}
                  </ErrorMessage>

                  <div className="row">
                    {!isNew && (
                      <Button
                        label="Delete"
                        size="small"
                        dangerous
                        stature="trivial"
                        className="flush-right"
                        onClick={() =>
                          actions
                            .deleteEntryFromCurrentJob(data._id)
                            .then(actions.refreshViewData)
                        }
                      />
                    )}
                  </div>
                </Form>
              )}
            />
          )}
        </AppContext.Consumer>
      </article>
    );
  }
}
