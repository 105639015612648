import * as React from 'react';
import { Link } from 'react-router-dom';
import AppContext from '../helpers/AppContext';
import { IItemAddress } from '../helpers/interfaces';

interface IProps {
  item: IItemAddress;
  visible: boolean;
}

const ItemChainLink = ({ item: { id, type }, visible }: IProps) => (
  <AppContext.Consumer>
    {({
      state: {
        data: { titles },
      },
    }) => (
      <Link
        to={`/${type}/${id}`}
        className={'item-chain-link' + (visible ? '' : ' hidden')}
        title={'Go to ' + titles[id]}
        aria-label={'Go to ' + titles[id]}
      >
        <i role="presentation" className="icon fa5 fas fa-link" />
      </Link>
    )}
  </AppContext.Consumer>
);

export default ItemChainLink;
