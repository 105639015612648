import { auth } from '../App';
import { IAddress, IItemAddress } from './interfaces';

export const makeRequest = async (
  path: string,
  method: string = 'GET',
  body?: string
): Promise<any> => {
  const req = new Request('https://api.employamp.com/v1/' + path, {
    method,
    body,
    headers: { Authorization: 'Bearer ' + (await auth.getIdToken()) },
  });

  const res: Response = await fetch(req);

  return res.json();

  // const data = await res.json();
  // console.log(req, data);
  // return data;
};

const getPath = (address: IAddress, queryStrings?: string[]): string => {
  if (!address) return '';

  let path: string = address.type;

  if (address.id) path += `/${address.id}`;

  if (Array.isArray(queryStrings) && queryStrings.length > 0) {
    path += `?${queryStrings.join('&')}`;
  }

  return path;
};

export const createData = async (
  address: IAddress,
  bodyObject: any
): Promise<any> => {
  const path = getPath(address);

  return await makeRequest(path, 'POST', JSON.stringify(bodyObject));
};

export const readData = async (
  address: IAddress,
  queryStrings?: string[]
): Promise<any> => {
  const path = getPath(address, queryStrings);

  return await makeRequest(path);
};

export const updateData = async (
  address: IItemAddress,
  bodyObject: any
): Promise<any> => {
  const path = getPath(address);

  // Delete the version property to avoid conflicts since this property is
  // managed directly by MongoDB.
  const bodyWithoutVersion = { ...bodyObject };
  if (bodyWithoutVersion.__v) delete bodyWithoutVersion.__v;

  return await makeRequest(path, 'PUT', JSON.stringify(bodyWithoutVersion));
};

export const deleteData = async (address: IItemAddress): Promise<any> => {
  const path = getPath(address);

  return await makeRequest(path, 'DELETE');
};
