import Table from 'antd/lib/table';
import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import getPathnameFromAddress from '../helpers/getPathnameFromAddress';
import { IItemAddress } from '../helpers/interfaces';
import singularize from '../helpers/singularize';
import AngleBracket from './AngleBracket';
import Button from './Button';
import EntryIcon, { EntryIconType } from './Entry/EntryIcon';
import ItemSelect from './ItemSelect';

interface IProps {
  attachItem?: (itemToAttach: IItemAddress) => void;
  columns: any[];
  data: any[];
  detachItem?: (itemToDetach: IItemAddress) => void;
  type: 'jobs' | 'entries' | 'companies' | 'contacts';
}

const ItemTable = ({ attachItem, columns, data, detachItem, type }: IProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  let title = '';
  let icon: EntryIconType;

  switch (type) {
    case 'entries':
      icon = 'task';
      title = 'Entries';
      break;
    case 'jobs':
      icon = 'job';
      title = 'Jobs';
      break;
    case 'companies':
      icon = 'company';
      title = 'Companies';
      break;
    case 'contacts':
      icon = 'contact';
      title = 'Contacts';
  }

  const getFirstCellLink = (value: any, record: any) => (
    <Link
      to={getPathnameFromAddress({
        type,
        id: record._id,
      })}
    >
      {value}
    </Link>
  );

  const getCellLink = (value: any, record: any) => (
    <Link
      tabIndex={-1}
      to={getPathnameFromAddress({
        type,
        id: record._id,
      })}
    >
      {value}
    </Link>
  );

  const colsWithRenderers = columns.map((col, i) => ({
    ...col,
    render: i === 0 ? getFirstCellLink : getCellLink,
  }));

  if (detachItem !== undefined) {
    colsWithRenderers.push({
      title: '',
      dataIndex: '_id',
      width: 100,
      render: (id: any) => (
        <Button
          size="small"
          dangerous
          stature="trivial"
          label="Detach"
          onClick={() => detachItem({ type, id })}
          className="detach"
        />
      ),
    });
  }

  const collapseButtonTitle = isCollapsed ? 'Expand table' : 'Collapse table';

  return (
    <article className={'item-table' + (isCollapsed ? ' collapsed' : '')}>
      <div className="header">
        <EntryIcon type={icon} />
        <span className="title">{title}</span>
        {data.length > 0 && (
          <button
            className="collapse"
            onClick={() => setIsCollapsed(!isCollapsed)}
            title={collapseButtonTitle}
            aria-label={collapseButtonTitle}
            aria-pressed={isCollapsed}
          >
            <AngleBracket />
          </button>
        )}
      </div>

      <Table
        className="collapsible"
        rowKey="_id"
        columns={colsWithRenderers}
        dataSource={data}
        sortDirections={['ascend', 'descend']}
        pagination={false}
        indentSize={0}
      />

      {attachItem && (
        <div className="collapsible footer">
          <ItemSelect
            placeholder={`Attach ${singularize(type)}`}
            type={type}
            valuesToFilter={data.map(item => item._id)}
            setValue={(id?: string) => {
              if (id) attachItem({ type, id });
            }}
          />
        </div>
      )}
    </article>
  );
};

export default ItemTable;
