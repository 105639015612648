import * as React from 'react';
import AppContext from '../../helpers/AppContext';
import SettingsModal from './SettingsModal';

export default class SettingsModalController extends React.Component<any> {
  public static contextType = AppContext;

  public componentDidMount() {
    this.context.actions.setModal(<SettingsModal />);
  }

  public componentWillUnmount() {
    this.context.actions.setModal(null);
  }

  public render() {
    return null;
  }
}
