import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as moment from 'moment';
import * as React from 'react';
import * as Yup from 'yup';
import AppContext from '../../helpers/AppContext';
import { statusOptions } from '../../helpers/dictionaries';
import JobEntryProps from '../../helpers/JobEntryProps';
import Button from '../Button';
import DateTime from '../DateTime';
import EntryIcon from './EntryIcon';
import WarnOfUnsavedChanges from '../WarnOfUnsavedChanges';

interface IState {
  dateTimeShouldRevertToInitVal: boolean;
}

export default class StatusEntry extends React.Component<
  JobEntryProps,
  IState
> {
  public state: IState = {
    dateTimeShouldRevertToInitVal: false,
  };

  public render() {
    const { isNew, depressEntryComposer, data } = this.props;

    return (
      <article className={'status' + (!isNew ? ' entry' : '')}>
        <AppContext.Consumer>
          {({ actions }) => (
            <Formik
              initialValues={
                data || {
                  type: 'STATUS',
                  sort: moment().toISOString(),
                  status: Object.keys(statusOptions)[0],
                }
              }
              validationSchema={Yup.object().shape({
                type: Yup.string()
                  .oneOf(['STATUS'])
                  .required(),
                sort: Yup.date()
                  .typeError('Date must be in a valid format.')
                  .required('Please indicate the date.'),
                status: Yup.string()
                  .oneOf(
                    Object.keys(statusOptions),
                    'The status must be a valid status.'
                  )
                  .required('Please choose a status.'),
              })}
              onSubmit={async (values, formActions) => {
                if (isNew && depressEntryComposer) {
                  await actions.addEntryToCurrentJob(values);

                  await actions.refreshViewData();
                  depressEntryComposer();
                } else {
                  await actions.updateEntryInCurrentJob(values);
                  await actions.refreshViewData();

                  formActions.resetForm();
                  this.setState({ dateTimeShouldRevertToInitVal: true });
                }
              }}
              onReset={() =>
                this.setState({ dateTimeShouldRevertToInitVal: true })
              }
              render={({
                errors,
                touched,
                isSubmitting,
                values,
                setFieldValue,
                setFieldTouched,
                dirty,
                initialValues,
                handleReset,
              }) => (
                <Form>
                  <WarnOfUnsavedChanges when={dirty} />
                  <div className="row medium-margin-bottom">
                    {!isNew && <EntryIcon type="status" />}

                    <Field
                      name="status"
                      id="status"
                      className="title"
                      component="select"
                      autoFocus={isNew}
                    >
                      {Object.entries(statusOptions).map(([key, value]) => (
                        <option key={key} value={key}>
                          {value}
                        </option>
                      ))}
                    </Field>

                    {isNew ? (
                      <>
                        <Button
                          type="submit"
                          label="Create"
                          size="medium"
                          stature="primary"
                          className="flush-right"
                          disabled={isSubmitting}
                        />
                        <Button
                          onClick={handleReset}
                          label="Clear"
                          disabled={isSubmitting || !dirty}
                        />
                      </>
                    ) : (
                      <>
                        <Button
                          type="submit"
                          label="Save"
                          size="small"
                          stature="primary"
                          className="flush-right"
                          disabled={isSubmitting || !dirty}
                        />
                        <Button
                          onClick={handleReset}
                          label="Cancel"
                          size="small"
                          disabled={isSubmitting || !dirty}
                        />
                      </>
                    )}
                  </div>
                  <ErrorMessage name="status">
                    {msg => <div className="form-validation-error">{msg}</div>}
                  </ErrorMessage>

                  <div className="row">
                    <label htmlFor="sort">Date</label>
                    <DateTime
                      name="sort"
                      inputId="sort"
                      initialValue={initialValues.sort}
                      defaultValue={values.sort}
                      shouldRevertToInitVal={
                        this.state.dateTimeShouldRevertToInitVal
                      }
                      didRevertToInitVal={() =>
                        this.setState({
                          dateTimeShouldRevertToInitVal: false,
                        })
                      }
                      hasError={!!(errors.sort && touched.sort)}
                      setValue={setFieldValue}
                      setTouched={setFieldTouched}
                    />

                    {!isNew && (
                      <Button
                        label="Delete"
                        size="small"
                        dangerous
                        stature="trivial"
                        onClick={() =>
                          actions
                            .deleteEntryFromCurrentJob(data._id)
                            .then(actions.refreshViewData)
                        }
                      />
                    )}
                  </div>
                  <ErrorMessage name="sort">
                    {msg => <div className="form-validation-error">{msg}</div>}
                  </ErrorMessage>
                </Form>
              )}
            />
          )}
        </AppContext.Consumer>
      </article>
    );
  }
}
