import normalizeUrl from 'normalize-url';
import * as React from 'react';
import { Component } from 'react';

interface IProps {
  name: string;
  value: string;
  id?: string;
  className?: string;
  autoFocus?: boolean;
  placeholder?: string;
  setValue: (value: string) => void;
  onChange: (...args: any[]) => any;
  onFocus: (...args: any[]) => any;
  onBlur: (...args: any[]) => any;
}

export default class UrlField extends Component<IProps> {
  private processUrl = (url: string): string => {
    try {
      return normalizeUrl(url, {
        stripHash: false,
        stripWWW: false,
        removeQueryParameters: [],
        sortQueryParameters: false,
      });
    } catch (err) {
      return url;
    }
  };

  private handleBlur = (event: any): void => {
    const url = this.processUrl(event.target.value);
    this.props.setValue(url);

    /*  Although giving the event an updated target value isn't necessary
        before passing it to onBlur, we'll do it just in case in a future
        version of Formik the onBlur function updates the field's value from
        the event's value. */
    const eventWithNewValue = event;
    eventWithNewValue.target.value = url;

    this.props.onBlur(eventWithNewValue);
  };

  private handleKeyDown = (event: any): void => {
    /*	If the user submits the form using the Enter key, we still want to
				normalize the URL before validation and submission. */
    if (event.key === 'Enter') {
      const url = this.processUrl(event.target.value);
      this.props.setValue(url);
    }
  };

  public render() {
    const {
      name,
      id,
      className,
      autoFocus,
      placeholder,
      value,
      onChange,
      onFocus,
    } = this.props;

    return (
      <input
        {...{
          name,
          id,
          className,
          autoFocus,
          placeholder,
          value,
          onChange,
          onFocus,
        }}
        type="text"
        inputMode="url"
        onBlur={this.handleBlur}
        onKeyDown={this.handleKeyDown}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck={false}
        data-gramm={false}
      />
    );
  }
}
