import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';
import AppContext from '../../helpers/AppContext';
import { createData } from '../../helpers/crudData';
import Button from '../Button';
import DateTime from '../DateTime';
import ItemSelect from '../ItemSelect';
import Modal from './Modal';

const NewJobModal = () => (
  <Modal className="new-job-modal" title="New job">
    {(closeModal: () => void) => (
      <AppContext.Consumer>
        {({ actions }) => (
          <Formik
            validationSchema={Yup.object().shape({
              title: Yup.string().required('Please include the job title.'),
              company: Yup.string()
                .nullable()
                .required('Please include the company.'),
              posted: Yup.date().typeError(
                'Posted date must be in a valid format.'
              ),
            })}
            initialValues={{
              title: '',
              company: '',
              posted: '',
            }}
            onSubmit={async (values, formActions) => {
              const type = 'jobs';
              const response = await createData({ type }, values);

              formActions.setSubmitting(false);
              actions.pushHistory(`/${type}/${response._id}`);
              closeModal();
            }}
            render={({
              errors,
              touched,
              isSubmitting,
              submitForm,
              setFieldValue,
              handleBlur,
              values,
              setFieldTouched,
            }) => (
              <Form>
                <div className="row medium-margin-bottom">
                  <label htmlFor="title">Title</label>
                  <Field
                    autoFocus
                    type="text"
                    name="title"
                    id="title"
                    className={errors.title && touched.title ? 'has-error' : ''}
                  />
                </div>
                <ErrorMessage name="title">
                  {msg => <div className="form-validation-error">{msg}</div>}
                </ErrorMessage>

                <div className="row medium-margin-bottom">
                  <label htmlFor="company">Company</label>
                  <ItemSelect
                    id="company"
                    placeholder="Select..."
                    type="companies"
                    setValue={(value?: string) =>
                      setFieldValue('company', value || null)
                    }
                    value={values.company}
                    hasError={!!(errors.company && touched.company)}
                    onBlur={handleBlur}
                  />
                </div>
                <ErrorMessage name="company">
                  {msg => <div className="form-validation-error">{msg}</div>}
                </ErrorMessage>

                <div className="row medium-margin-bottom">
                  <label htmlFor="posted">Date posted</label>
                  <DateTime
                    name="posted"
                    inputId="posted"
                    type="DATE"
                    defaultValue={values.posted}
                    setValue={setFieldValue}
                    setTouched={setFieldTouched}
                    hasError={!!(errors.posted && touched.posted)}
                  />
                </div>
                <ErrorMessage name="posted">
                  {msg => <div className="form-validation-error">{msg}</div>}
                </ErrorMessage>

                <div className="row">
                  <Button
                    type="submit"
                    stature="primary"
                    label="Add"
                    icon="plus"
                    disabled={isSubmitting}
                    onClick={submitForm}
                  />
                </div>
              </Form>
            )}
          />
        )}
      </AppContext.Consumer>
    )}
  </Modal>
);

export default NewJobModal;
