import * as React from 'react';
import AngleBracket from './AngleBracket';

interface IProps {
  isCollapsed: boolean;
  toggleCollapsed: () => void;
}

const CollapseSideMenuButton = ({ isCollapsed, toggleCollapsed }: IProps) => {
  const collapseButtonTitle = isCollapsed
    ? 'Expand side menu'
    : 'Collapse side menu';

  return (
    <button
      type="button"
      id="collapse-side-menu-button"
      className="round-nav-button"
      title={collapseButtonTitle}
      aria-label={collapseButtonTitle}
      aria-pressed={isCollapsed}
      onClick={toggleCollapsed}
    >
      <AngleBracket />
    </button>
  );
};

export default CollapseSideMenuButton;
