import * as Yup from 'yup';

export const linkSchema = Yup.object().shape({
  label: Yup.string(),
  value: Yup.string()
    .url('The URL must be in a valid URL format.')
    .required('Please include a URL.'),
});

export const emailSchema = Yup.object().shape({
  label: Yup.string(),
  value: Yup.string()
    .email('The email must be in a valid format.')
    .required('Please include an email address.'),
});

export const phoneSchema = Yup.object().shape({
  label: Yup.string(),
  value: Yup.string().required('Please include a phone number.'),
});
