import { IAddress } from './interfaces';

const getTitle = (address: IAddress | null, data?: any): string => {
  let title: string = '';

  if (!address) return title;

  // If the address is for a particular item and we have its data, create a
  // title from the
  if (address.id && data) {
    // Make sure we're getting data from the correct place.
    const item = data.primary || data;

    // Create a title based on the item type.
    switch (address.type) {
      case 'jobs':
        title = item.title;
        break;
      case 'contacts':
        title = `${item.name.first} ${item.name.last}${
          item.name.suffix ? ', ' + item.name.suffix : ''
        }`;
        break;
      case 'companies':
        title = item.name;
    }
  } else if (address.type) {
    // If we don't have a particular item, just create a title by capitalizing
    // the address type.
    title = address.type.charAt(0).toUpperCase() + address.type.slice(1);
  }

  return title;
};

export default getTitle;
