import * as React from 'react';
import { Component } from 'react';
import AsyncSelect from 'react-select/lib/Async';
import { ValueType } from 'react-select/lib/types';
import { makeRequest } from '../helpers/crudData';
import { IAddressSuggestion, ISelectOption } from '../helpers/interfaces';

interface IProps {
  value: string;
  placeholder?: string;
  id?: string;
  className?: string;
  setValue: (value: string) => void;
  onBlur: any;
  hasError?: boolean;
}

export default class AddressField extends Component<IProps> {
  private handleChange = (selectedOption: ValueType<ISelectOption>) => {
    const value: string =
      selectedOption && !Array.isArray(selectedOption)
        ? selectedOption.value
        : '';

    this.props.setValue(value);
  };

  private fetchSuggestions = async (
    query: string
  ): Promise<IAddressSuggestion[]> => {
    const trimmedQ = query.trim();

    if (!trimmedQ) return [];

    const suggestions = await makeRequest(
      'autocomplete-address?query=' + trimmedQ
    );

    return suggestions;
  };

  private loadOptions = async (
    inputValue: string
  ): Promise<ISelectOption[]> => {
    /*  When the component initially mounts and loads the options,
        react-select's input's value will be an empty string. This is what is
        provided as the inputValue parameter the first time this method is
        called. This is useless since we need an actual query to fetch
        suggestions, so we'll use the value prop as
        the query when the provided inputValue is empty. */
    const query = inputValue ? inputValue : this.props.value;

    const suggestions = await this.fetchSuggestions(query);

    const options = suggestions.map(suggestion =>
      this.valueToOption(suggestion.address)
    );

    return options;
  };

  private valueToOption = (value: string): ISelectOption => ({
    value,
    label: value,
  });

  private handleInputChange = (
    value: string,
    { action }: { action: string }
  ) => {
    if (action === 'input-change') this.handleChange(this.valueToOption(value));
  };

  public render() {
    const { id, className, placeholder, value, onBlur, hasError } = this.props;

    return (
      <AsyncSelect
        value={value ? this.valueToOption(value) : null}
        placeholder={placeholder || ''}
        className={
          'single-dropdown address-field' + (className ? ' ' + className : '')
        }
        classNamePrefix={hasError ? 'has-error' : undefined}
        onChange={this.handleChange}
        onBlur={onBlur}
        isClearable={true}
        defaultOptions={true}
        loadOptions={this.loadOptions}
        inputValue={value}
        inputId={id}
        onInputChange={this.handleInputChange}
      />
    );
  }
}
