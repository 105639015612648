import * as React from 'react';
import { Component } from 'react';
import ChatLogEntry from './Entry/ChatLogEntry';
import EntryIcon from './Entry/EntryIcon';
import EventEntry from './Entry/EventEntry';
import PostEntry from './Entry/PostEntry';
import StatusEntry from './Entry/StatusEntry';
import TaskEntry from './Entry/TaskEntry';

type SelectedEntry =
  | 'status'
  | 'post'
  | 'task'
  | 'event'
  | 'chatLog'
  | undefined;

interface IState {
  selectedEntryType: SelectedEntry;
}

export default class EntryComposer extends Component<any, IState> {
  public state: IState = {
    selectedEntryType: undefined,
  };

  public pressEntryType = (type: SelectedEntry) => {
    if (this.state.selectedEntryType !== type)
      this.setState({ selectedEntryType: type });
    // If the type that we're pressing is already pressed, simply toggle it off.
    else this.setState({ selectedEntryType: undefined });
  };

  public render() {
    const { selectedEntryType } = this.state;

    let entryComponent;
    const common = {
      isNew: true as true,
      depressEntryComposer: () =>
        this.setState({ selectedEntryType: undefined }),
    };

    switch (selectedEntryType) {
      case 'status':
        entryComponent = <StatusEntry {...common} />;
        break;
      case 'post':
        entryComponent = <PostEntry {...common} />;
        break;
      case 'task':
        entryComponent = <TaskEntry {...common} />;
        break;
      case 'event':
        entryComponent = <EventEntry {...common} />;
        break;
      case 'chatLog':
        entryComponent = <ChatLogEntry {...common} />;
    }

    return (
      <section className="entryComposer">
        <ul className="typeSelector">
          <li className="bookend">
            <span>New...</span>
          </li>
          <li
            className={selectedEntryType === 'status' ? 'active' : ''}
            onClick={() => this.pressEntryType('status')}
            title="Create new status entry"
          >
            <span>
              <EntryIcon type="status" />
              Status
            </span>
          </li>
          <li
            className={selectedEntryType === 'post' ? 'active' : ''}
            onClick={() => this.pressEntryType('post')}
            title="Create new post"
          >
            <span>
              <EntryIcon type="post" />
              Post
            </span>
          </li>
          <li
            className={selectedEntryType === 'task' ? 'active' : ''}
            onClick={() => this.pressEntryType('task')}
            title="Create new task"
          >
            <span>
              <EntryIcon type="task" />
              Task
            </span>
          </li>
          <li
            className={selectedEntryType === 'event' ? 'active' : ''}
            onClick={() => this.pressEntryType('event')}
            title="Create new event"
          >
            <span>
              <EntryIcon type="event" />
              Event
            </span>
          </li>
          <li
            className={selectedEntryType === 'chatLog' ? 'active' : ''}
            onClick={() => this.pressEntryType('chatLog')}
            title="Create new chat log entry"
          >
            <span>
              <EntryIcon type="chatLog" />
              Chat log
            </span>
          </li>
        </ul>

        {entryComponent && (
          <div className="entryComposerContent">
            <div className="entryComposerConfig">{entryComponent}</div>
          </div>
        )}
      </section>
    );
  }
}
