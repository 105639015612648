import * as auth0 from 'auth0-js';
import history from './helpers/history';

export default class Auth {
  private accessToken: string = '';
  private idToken: string = '';
  private expiresAt: any;
  private tokenRenewalTimeout: any;
  private userProfile: any;

  public auth0 = new auth0.WebAuth({
    domain: 'auth.employamp.com',
    clientID: 'AJLReSK6Y5YVqK570Sv5GfLwn6tcIMh9',
    redirectUri:
      location.host === 'localhost:8080'
        ? 'http://localhost:8080/authenticate'
        : 'https://app.employamp.com/authenticate',
    audience: 'https://employamp.auth0.com/userinfo',
    responseType: 'token id_token',
    scope: 'openid', // Add 'email' to get access to user's email.
  });

  constructor() {
    this.scheduleRenewal();
  }

  private scheduleRenewal = () => {
    const timeout = this.expiresAt - Date.now();

    if (timeout > 0)
      this.tokenRenewalTimeout = setTimeout(this.renewSession, timeout);
  };

  private setSession = (authResult: any) => {
    // Set isSignedIn flag in localStorage.
    localStorage.setItem('isSignedIn', 'true');

    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.expiresAt = authResult.expiresIn * 1000 + new Date().getTime();

    // Schedule a token renewal.
    this.scheduleRenewal();
  };

  private handleSessionError = (err: auth0.Auth0Error) => {
    this.signOut();

    if (err.error === 'login_required') {
      this.signIn();
      return;
    }

    console.log(err);
    alert(
      `Could not get a new token (${err.error}: ${
        (err as any).error_description
      }).`
    );
  };

  public signIn = () => {
    this.auth0.authorize();
  };

  public handleAuthentication = () => {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
      } else if (err) {
        history.replace('/');
        console.log(err);
        alert(`Error: ${err.error}. Check the console for further details.`);
      }
    });
  };

  public renewSession = () => {
    this.auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
      } else if (err) this.handleSessionError(err);
    });
  };

  public getIdToken = async () => {
    return (
      this.idToken ||
      new Promise((resolve, reject) =>
        this.auth0.checkSession({}, (err, authResult) => {
          if (authResult && authResult.accessToken && authResult.idToken) {
            this.setSession(authResult);
            resolve(authResult.idToken);
          } else if (err) {
            this.handleSessionError(err);
            reject(err);
          }
        })
      )
    );
  };

  public getUserProfile = async () => {
    return (
      this.userProfile ||
      new Promise((resolve, reject) =>
        this.auth0.client.userInfo(this.accessToken, (err, profile) => {
          if (profile) {
            console.log(profile);
            this.userProfile = profile;
            resolve(profile);
          } else if (err) {
            console.log(err);
            reject(err);
          }
        })
      )
    );
  };

  public signOut = () => {
    // Remove tokens and expiry time.
    this.accessToken = '';
    this.idToken = '';
    this.expiresAt = 0;

    // Remove user profile.
    this.userProfile = null;

    // Clear token renewal.
    clearTimeout(this.tokenRenewalTimeout);

    // Remove isSignedIn flag from localStorage.
    localStorage.removeItem('isSignedIn');

    // Navigate to the home route.
    history.replace('/');
  };

  // Check whether the current time is past the access token's expiry time.
  public isAuthenticated = () => new Date().getTime() < this.expiresAt;
}
