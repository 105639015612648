import { readData } from './crudData';
import { IItemAddress } from './interfaces';

const getTitleDictionary = async (addresses: IItemAddress[]): Promise<any> => {
  // Filter out addresses that don't have ids.
  const filteredAddresses = addresses.filter(address => address.id);

  const givenAddresses: boolean =
    Array.isArray(filteredAddresses) && filteredAddresses.length > 0;
  if (!givenAddresses) return {};

  // Combine addresses of the same type into one address with multiple ids so we
  // can make fewer requests.
  const combinedAddresses = filteredAddresses.reduce(
    (
      accAddresses: IItemAddress[],
      address: IItemAddress,
      i: number
    ): IItemAddress[] => {
      // Skip the first address since it's already inserted into
      // accAddresses when it's initialized.
      if (i === 0) return accAddresses;

      // Look for a previous address added to accAddresses that
      // matches the current address type.
      const indexOfSameType = accAddresses.findIndex(
        prevAddress => prevAddress.type === address.type
      );

      // Make a shallow copy to avoid mutating the accAddresses parameter.
      const newAccAddresses = [...accAddresses];

      // If we found one, add the id of the current address to the previously
      // added address. If not, add the current address itself.
      if (indexOfSameType > -1) {
        // We're being careful not to mutate the accAddresses parameter.
        newAccAddresses[indexOfSameType] = {
          ...accAddresses[indexOfSameType],
        };
        newAccAddresses[indexOfSameType].id += ',' + address.id;
      } else newAccAddresses.push(address);

      return newAccAddresses;
    },
    [filteredAddresses[0]]
  );

  // Fetch a title for each address in combinedAddresses.
  const fetchedTitles: any[] = await Promise.all(
    combinedAddresses.map(address => readData(address, ['titleOnly=true']))
  );

  // Flatten fetchedTitles so all elements are address objects instead of
  // arrays.
  const flattenedTitles: {
    _id: string;
    title: string;
  }[] = fetchedTitles.reduce(
    (flatArray, element) => flatArray.concat(element),
    []
  );

  const dictionary: { [key: string]: string } = {};

  flattenedTitles.forEach(item => {
    // Define a property in dictionary for each item with the item's _id
    // as the key and the corresponding title as the value.
    dictionary[item._id] = item.title;
  });

  return dictionary;
};

export default getTitleDictionary;
