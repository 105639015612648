import * as React from 'react';
import * as Yup from 'yup';
import AppContext from '../helpers/AppContext';
import copyToClipboard from '../helpers/copyToClipboard';
import FieldArrayItemModal from './Modal/FieldArrayItemModal';

export interface IValueMetaAndItemSchema {
  valueType: 'link' | 'email' | 'phone';
  valueFieldLabel: string;
  valueNameForHeading: string;
  validationSchema: Yup.ObjectSchema<{}>;
}

interface IProps extends IValueMetaAndItemSchema {
  value: string;
  label?: string;
  href?: string;
  setValue: (value: string, label?: string) => void;
  deleteItem: () => void;
}

const FieldArrayItem = (props: IProps) => {
  const { value, label, href } = props;

  const labelToShow = label || value;

  return (
    <div className="field-array-item" aria-labelledby="field-array-item-label">
      {href ? (
        <a
          id="field-array-item-label"
          className="label"
          href={href || ''}
          target="_blank"
          rel="noopener noreferrer"
        >
          {labelToShow}
        </a>
      ) : (
        <span id="field-array-item-label" className="label">
          {labelToShow}
        </span>
      )}

      <button
        type="button"
        className="copy"
        title="Copy"
        aria-label="Copy"
        onClick={() => copyToClipboard(value)}
      >
        <i className="icon fa5 far fa-copy" role="presentation" />
      </button>

      <AppContext.Consumer>
        {({ actions }) => (
          <button
            type="button"
            className="edit"
            title="Edit"
            aria-label="Edit"
            onClick={() => actions.setModal(<FieldArrayItemModal {...props} />)}
          >
            <i className="icon fa5 fas fa-pencil-alt" role="presentation" />
          </button>
        )}
      </AppContext.Consumer>
    </div>
  );
};

export default FieldArrayItem;
