import * as H from 'history';
import * as React from 'react';
import { Component } from 'react';
import {
  Prompt,
  Redirect,
  Route,
  RouteComponentProps,
  withRouter,
} from 'react-router-dom';
import logo from './assets/employamp-logo.svg';
import Auth from './Auth';
import HelpButton from './components/HelpButton';
import SettingsModalController from './components/Modal/SettingsModalController';
import SideMenu from './components/SideMenu';
import SignOut from './components/SignOut';
import SignOutButton from './components/SignOutButton';
import View from './components/View';
import AppContext from './helpers/AppContext';
import { updateData } from './helpers/crudData';
import getAddressFromPathname from './helpers/getAddressFromPathname';
import getPathnameFromAddress from './helpers/getPathnameFromAddress';
import getTitle from './helpers/getTitle';
import getViewData from './helpers/getViewData';
import { IAddress, IIndexAddress, IItemAddress } from './helpers/interfaces';
import setAllQSParams from './helpers/setAllQSParams';
import setDocumentTitle from './helpers/setDocumentTitle';
import setQSParams from './helpers/setQSParams';

export const auth = new Auth();

const defaultAddress: IIndexAddress = {
  type: 'jobs',
};

const UNSAVED_CHANGES_WARNING = 'Changes you made may not be saved.';

export interface IAppState {
  isSignedIn: boolean;
  userProfile: any;
  address: IAddress;
  modal: JSX.Element | null;
  indexLayout: 'TABLE';
  data: {
    primary: any;
    titles: any;
    jobs: any[];
    entries: any[];
    companies: any[];
    contacts: any[];
  };
  instancesOfUnsavedChanges: number;
  isLoading: boolean;
  error: null | Error;
}

class App extends Component<RouteComponentProps, IAppState> {
  public state: IAppState = {
    isSignedIn: localStorage.getItem('isSignedIn') === 'true',
    userProfile: {},
    address:
      this.props.location.pathname === '/'
        ? defaultAddress
        : getAddressFromPathname(this.props.location.pathname),
    modal: null,
    indexLayout: 'TABLE',
    data: {
      primary: null,
      titles: {},
      jobs: [],
      entries: [],
      companies: [],
      contacts: [],
    },
    instancesOfUnsavedChanges: 0,
    isLoading: true,
    error: null,
  };

  public componentDidMount() {
    const authenticating =
      location.pathname === '/authenticate' &&
      /access_token|id_token|error/.test(location.hash);

    if (authenticating) {
      auth.handleAuthentication();
      this.props.history.push('/');
      this.setState({ isSignedIn: true });
    } else if (localStorage.getItem('isSignedIn') !== 'true') {
      this.signOut();
    }

    // Whenever the user navigates somewhere, clear any modals.
    // TODO: Fix: Modal clears only when tested locally, not when deployed.
    this.props.history.listen(() => this.setState({ modal: null }));
  }

  public getHrefWithoutSettings = (locationObj: H.Location) =>
    locationObj.pathname.replace(/\/settings/gi, '') +
    locationObj.search +
    locationObj.hash;

  public handleBeforeUnload(e: BeforeUnloadEvent) {
    e.preventDefault();
    e.returnValue = UNSAVED_CHANGES_WARNING;
  }

  public addOrRemoveBeforeUnloadHandler(shouldAdd: boolean) {
    if (shouldAdd)
      window.addEventListener('beforeunload', this.handleBeforeUnload);
    else window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  public async componentDidUpdate(
    prevProps: RouteComponentProps,
    prevState: IAppState
  ) {
    if (
      this.getHrefWithoutSettings(this.props.location) !==
      this.getHrefWithoutSettings(prevProps.location)
    ) {
      this.setState({
        isLoading: true,
        address: getAddressFromPathname(this.props.location.pathname),
      });
      this.refreshViewData();
    }

    if (
      this.state.instancesOfUnsavedChanges !==
      prevState.instancesOfUnsavedChanges
    ) {
      this.addOrRemoveBeforeUnloadHandler(
        this.state.instancesOfUnsavedChanges > 0
      );
    }
  }

  public refreshViewData = async (prefetchedPrimary?: any) => {
    setDocumentTitle('Loading...');

    const address = getAddressFromPathname(this.props.location.pathname);

    if (address.type) {
      const data = await getViewData(address, prefetchedPrimary);

      setDocumentTitle(getTitle(address, data.primary));
      this.setState({
        data,
        isLoading: false,
      });
    }
  };

  public getIndexPageLayout = () =>
    new URLSearchParams(this.props.location.search).get('layout') || 'TABLE';

  public setModal = (modal: JSX.Element | null): void =>
    this.setState({ modal });

  public signOut = () => {
    auth.signOut();

    // Navigate to the sign in page immediately after signing out.
    auth.signIn();
  };

  public addEntryToCurrentJob = (newEntry: any) => {
    const updatedEntries = this.state.data.primary.entries.concat(newEntry);
    const updatedJob = {
      _id: this.state.data.primary._id,
      entries: updatedEntries,
    };

    return updateData(this.state.address as IItemAddress, updatedJob);
  };

  public updateEntryInCurrentJob = (updatedEntry: any) => {
    const job: { _id: string; entries: { _id: string }[] } = this.state.data
      .primary;

    // Find the index of the entry we want to replace (update).
    const index = job.entries.findIndex(
      entry => entry._id === updatedEntry._id
    );

    // Use slice to make a shallow copy of entries before replacing the entry so
    // we don't mutate state.
    const updatedEntries = job.entries.slice();
    if (index > -1) updatedEntries.splice(index, 1, updatedEntry);

    const updatedJob = {
      _id: job._id,
      entries: updatedEntries,
    };

    return updateData(this.state.address as IItemAddress, updatedJob);
  };

  public deleteEntryFromCurrentJob = (entryId: string) => {
    const job: { _id: string; entries: { _id: string }[] } = this.state.data
      .primary;

    // Find the index of the entry we want to delete.
    const index = job.entries.findIndex(entry => entry._id === entryId);

    // Use slice to make a shallow copy of entries before removing the entry so
    // we don't mutate state.
    const updatedEntries = job.entries.slice();
    if (index > -1) updatedEntries.splice(index, 1);

    const updatedJob = {
      _id: job._id,
      entries: updatedEntries,
    };

    return updateData(this.state.address as IItemAddress, updatedJob);
  };

  public render() {
    const { history } = this.props;
    const { isSignedIn, modal } = this.state;

    // Render nothing if the user is not signed in.
    if (!isSignedIn) return null;

    const context = {
      actions: {
        pushHistory: (pathname: string) => this.props.history.push(pathname),
        setAppState: (state: Partial<IAppState>) =>
          this.setState(state as IAppState),
        setModal: this.setModal,
        setQSParams: (qsParams: any) => setQSParams(history, qsParams),
        setAllQSParams: (qsParams: any) => setAllQSParams(history, qsParams),
        refreshViewData: this.refreshViewData,
        deleteEntryFromCurrentJob: this.deleteEntryFromCurrentJob,
        addEntryToCurrentJob: this.addEntryToCurrentJob,
        updateEntryInCurrentJob: this.updateEntryInCurrentJob,
      },
      state: {
        data: this.state.data,
        /*  Check both App's state and if the state's address equals an
            address dynamically created from the pathname to see if we are
            loading data because these two aren't always in sync. */
        isLoading:
          this.state.isLoading ||
          JSON.stringify(this.state.address) !==
            JSON.stringify(
              getAddressFromPathname(this.props.location.pathname)
            ),
        error: this.state.error,
        indexLayout: this.getIndexPageLayout(),
        address: this.state.address,
        match: this.props.match,
        location: this.props.location,
        history: this.props.history,
        instancesOfUnsavedChanges: this.state.instancesOfUnsavedChanges,
      },
    };

    return (
      <AppContext.Provider value={context}>
        <Prompt
          when={this.state.instancesOfUnsavedChanges > 0}
          message={UNSAVED_CHANGES_WARNING}
        />
        <div className="App">
          <Route
            path="/:any*/sign-out"
            render={() => <SignOut signOut={this.signOut} />}
          />

          <Route
            path="/"
            exact
            render={() => (
              <Redirect to={getPathnameFromAddress(defaultAddress)} />
            )}
          />

          {/* We need SettingsModal to appear over something so the View won't
              be confused. So, if the user goes directly to /settings, send
              them to a route that will give View something to show behind
              SettingsModal.
          */}
          <Route
            path="/settings"
            exact
            render={() => <Redirect to="/jobs/settings" />}
          />

          <Route path="/:any*/settings" component={SettingsModalController} />

          {modal}

          <header>
            <img id="logo" alt="EmployAmp logo" src={logo} />

            <div id="header-buttons">
              <HelpButton />
              <SignOutButton />
            </div>
          </header>

          <main>
            <SideMenu />
            <View />
          </main>
        </div>
      </AppContext.Provider>
    );
  }
}

export default withRouter(App);
