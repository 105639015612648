import * as React from 'react';
import { Link } from 'react-router-dom';

const SignOutButton = () => (
  <Link
    to="/sign-out"
    id="sign-out-button"
    className="round-nav-button header-button"
    title="Sign out"
    aria-label="Sign out"
  >
    <i role="presentation" className="icon fa5 fas fa-door-open" />
  </Link>
);

export default SignOutButton;
