import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';
import AppContext from '../../helpers/AppContext';
import { updateData } from '../../helpers/crudData';
import { jobHoursOptions, jobTypeOptions } from '../../helpers/dictionaries';
import { linkSchema } from '../../helpers/validationSchemas';
import AddressField from '../AddressField';
import Button from '../Button';
import DateTime from '../DateTime';
import FieldArrayItem from '../FieldArrayItem';
import ItemChainLink from '../ItemChainLink';
import ItemSelect from '../ItemSelect';
import FieldArrayItemModal from '../Modal/FieldArrayItemModal';
import WarnOfUnsavedChanges from '../WarnOfUnsavedChanges';

interface IProps {
  deleteItem: () => Promise<void>;
  refresh: (...args: any[]) => any;
}

interface IState {
  dateTimeShouldRevertToInitVal: boolean;
}

export default class JobSidebar extends React.Component<IProps, IState> {
  public static contextType = AppContext;

  public state: IState = {
    dateTimeShouldRevertToInitVal: false,
  };

  public render() {
    // Use destructuring and a self-calling function to pick out specific
    // properties from the primary data to use as Formik's initial values.
    const initialVals = (({
      _id,
      title,
      company,
      saved,
      posted,
      hours,
      type,
      location,
      links,
      description,
    }) => ({
      _id,
      title,
      company,
      saved,
      // The posted field cannot be null because that's how <DateTime /> forces
      // a validation error.
      posted: posted || '',
      hours,
      type,
      location,
      links,
      description,
    }))(this.context.state.data.primary);

    const validationSchema = Yup.object().shape({
      title: Yup.string().required('Please include job title.'),
      company: Yup.string()
        .nullable()
        .required('Please include company.'),
      saved: Yup.date()
        .typeError('Saved date must be in a valid format.')
        .required('Please indicate the date saved.'),
      // Although posted is an optional field, we do not mark it as nullable().
      // This is because we always want to have a validation error if it is set
      // to null because that's how <DateTime /> forces a validation error.
      posted: Yup.date().typeError('Posted date must be in a valid format.'),
      hours: Yup.string().oneOf(Object.keys(jobHoursOptions)),
      type: Yup.string().oneOf(Object.keys(jobTypeOptions)),
      location: Yup.string(),
      links: Yup.array().of(linkSchema),
      description: Yup.string(),
    });

    return (
      <section className="item-sidebar">
        <AppContext.Consumer>
          {({ actions: { setModal } }) => (
            <Formik
              initialValues={initialVals}
              validationSchema={validationSchema}
              onSubmit={(values, formActions) => {
                updateData({ type: 'jobs', id: values._id }, values)
                  .then(this.props.refresh)
                  .then(formActions.resetForm)
                  .then(() => formActions.setSubmitting(false));
                this.setState({ dateTimeShouldRevertToInitVal: true });
              }}
              onReset={() =>
                this.setState({ dateTimeShouldRevertToInitVal: true })
              }
              render={({
                errors,
                touched,
                isSubmitting,
                handleChange,
                handleBlur,
                values,
                setFieldValue,
                setFieldTouched,
                dirty,
                initialValues,
                handleReset,
              }) => (
                <Form>
                  <WarnOfUnsavedChanges when={dirty} />
                  <div className="row medium-margin-bottom">
                    <Field
                      type="text"
                      name="title"
                      autoComplete="off"
                      placeholder="Job title"
                      aria-label="Job title"
                      className={
                        'title' +
                        (errors.title && touched.title ? ' has-error' : '')
                      }
                    />

                    <Button
                      type="submit"
                      label="Save"
                      stature="primary"
                      disabled={isSubmitting || !dirty}
                    />
                    <Button
                      label="Cancel"
                      onClick={handleReset}
                      disabled={isSubmitting || !dirty}
                    />
                  </div>
                  <ErrorMessage name="title">
                    {msg => <div className="form-validation-error">{msg}</div>}
                  </ErrorMessage>

                  <div className="row medium-margin-bottom">
                    <label htmlFor="company">Company</label>
                    <ItemSelect
                      id="company"
                      value={values.company}
                      placeholder="Select..."
                      type="companies"
                      setValue={(value?: string) =>
                        setFieldValue('company', value || null)
                      }
                      hasError={!!(errors.company && touched.company)}
                      onBlur={handleBlur}
                    />
                    <ItemChainLink
                      item={{
                        type: 'companies',
                        id: initialValues.company,
                      }}
                      visible={
                        initialValues.company &&
                        values.company === initialValues.company
                      }
                    />
                  </div>
                  <ErrorMessage name="company">
                    {msg => <div className="form-validation-error">{msg}</div>}
                  </ErrorMessage>

                  <div className="row medium-margin-bottom">
                    <label htmlFor="saved">Saved</label>
                    <DateTime
                      name="saved"
                      inputId="saved"
                      type="DATE"
                      initialValue={initialValues.saved}
                      defaultValue={values.saved}
                      shouldRevertToInitVal={
                        this.state.dateTimeShouldRevertToInitVal
                      }
                      didRevertToInitVal={() =>
                        this.setState({ dateTimeShouldRevertToInitVal: false })
                      }
                      hasError={!!(errors.saved && touched.saved)}
                      setValue={setFieldValue}
                      setTouched={setFieldTouched}
                    />
                    <Button
                      label="Delete job"
                      dangerous
                      size="small"
                      onClick={this.props.deleteItem}
                    />
                  </div>
                  <ErrorMessage name="saved">
                    {msg => <div className="form-validation-error">{msg}</div>}
                  </ErrorMessage>

                  <div className="row medium-margin-bottom">
                    <label htmlFor="posted">Posted</label>
                    <DateTime
                      name="posted"
                      inputId="posted"
                      type="DATE"
                      initialValue={initialValues.posted}
                      defaultValue={values.posted}
                      shouldRevertToInitVal={
                        this.state.dateTimeShouldRevertToInitVal
                      }
                      didRevertToInitVal={() =>
                        this.setState({ dateTimeShouldRevertToInitVal: false })
                      }
                      hasError={!!(errors.posted && touched.posted)}
                      setValue={setFieldValue}
                      setTouched={setFieldTouched}
                    />
                  </div>
                  <ErrorMessage name="posted">
                    {msg => <div className="form-validation-error">{msg}</div>}
                  </ErrorMessage>

                  <div className="row small-margin-bottom">
                    <label>Links</label>
                  </div>
                  <FieldArray
                    name="links"
                    render={arrayHelpers => (
                      <div className="field-array-items medium-margin-bottom">
                        {values.links.map((link: any, i: number) => (
                          <FieldArrayItem
                            key={i}
                            label={link.label}
                            href={link.value}
                            value={link.value}
                            valueType="link"
                            valueFieldLabel="URL"
                            valueNameForHeading="link"
                            setValue={(value: string, label?: string) => {
                              setFieldValue(`links[${i}].value`, value);
                              setFieldValue(`links[${i}].label`, label);
                            }}
                            deleteItem={() => arrayHelpers.remove(i)}
                            validationSchema={linkSchema}
                          />
                        ))}

                        <Button
                          size="small"
                          stature="trivial"
                          icon="plus"
                          label="Add link"
                          className="add"
                          onClick={() =>
                            setModal(
                              <FieldArrayItemModal
                                valueType="link"
                                valueFieldLabel="URL"
                                valueNameForHeading="link"
                                validationSchema={linkSchema}
                                isNew
                                addItem={(value: string, label?: string) =>
                                  arrayHelpers.insert(values.links.length, {
                                    value,
                                    label,
                                  })
                                }
                              />
                            )
                          }
                        />
                      </div>
                    )}
                  />

                  <div className="row medium-margin-bottom">
                    <label htmlFor="hours">Hours</label>
                    <Field name="hours" id="hours" component="select">
                      {Object.entries(jobHoursOptions).map(([key, value]) => (
                        <option key={key} value={key}>
                          {value}
                        </option>
                      ))}
                    </Field>
                  </div>

                  <div className="row medium-margin-bottom">
                    <label htmlFor="type">Type</label>
                    <Field name="type" id="type" component="select">
                      {Object.entries(jobTypeOptions).map(([key, value]) => (
                        <option key={key} value={key}>
                          {value}
                        </option>
                      ))}
                    </Field>
                  </div>

                  <div className="row small-margin-bottom">
                    <label htmlFor="location">Location</label>
                  </div>
                  <div className="row medium-margin-bottom">
                    <AddressField
                      value={values.location}
                      setValue={(value: string) =>
                        setFieldValue('location', value)
                      }
                      id="location"
                      placeholder="Type..."
                      hasError={!!(errors.location && touched.location)}
                      onBlur={handleBlur}
                    />
                  </div>
                  <ErrorMessage name="location">
                    {msg => <div className="form-validation-error">{msg}</div>}
                  </ErrorMessage>

                  <div className="row small-margin-bottom">
                    <label htmlFor="description">Description</label>
                  </div>
                  <textarea
                    id="description"
                    className="medium-margin-bottom"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage name="description">
                    {msg => <div className="form-validation-error">{msg}</div>}
                  </ErrorMessage>
                </Form>
              )}
            />
          )}
        </AppContext.Consumer>
      </section>
    );
  }
}
