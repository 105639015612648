import * as React from 'react';

export type EntryIconType =
  | 'status'
  | 'post'
  | 'task'
  | 'event'
  | 'chatLog'
  | 'job'
  | 'company'
  | 'contact'
  | 'file'
  | undefined;

const EntryIcon = ({ type }: { type: EntryIconType }) => {
  let icon = '';
  switch (type) {
    case 'status':
      icon = 'fa-star';
      break;
    case 'post':
      icon = 'fa-edit';
      break;
    case 'task':
      icon = 'fa-check-square';
      break;
    case 'event':
      icon = 'fa-calendar-alt';
      break;
    case 'chatLog':
      icon = 'fa-comment-alt';
      break;
    case 'job':
      icon = 'fa-briefcase';
      break;
    case 'company':
      icon = 'fa-building';
      break;
    case 'contact':
      icon = 'fa-users';
      break;
    case 'file':
      icon = 'fa-copy';
      break;
  }

  return (
    <div className="entryIcon">
      <i className={'icon fa fa5 ' + icon} />
    </div>
  );
};

export default EntryIcon;
