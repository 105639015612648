import * as React from 'react';

interface IProps {
  onClick?: any;
  id?: string;
  className?: string;
}

const AngleBracket = ({ onClick, id, className }: IProps) => (
  <svg
    onClick={onClick}
    id={id}
    className={'angleBracket icon' + (className ? ' ' + className : '')}
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="667px"
    height="1025px"
    viewBox="-12.499 -12.5 667 1025"
  >
    <polygon
      fill="#FFF"
      points="642.162,126.162 526.926,0 0,499.911 526.926,1000 642.162,874.016 237.636,499.911 "
    />
  </svg>
);

export default AngleBracket;
