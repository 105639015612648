import * as React from 'react';
import AppContext from '../../helpers/AppContext';
import singularize from '../../helpers/singularize';
import Button from '../Button';
import Modal from './Modal';

type ConfirmDeleteModalProps = (
  | { itemTitle: string; title?: never }
  | { itemTitle?: never; title: string }) & {
  itemType: string;
  deleteItem: () => void;
};

const ConfirmDeleteModal = ({
  itemTitle,
  title,
  itemType,
  deleteItem,
}: ConfirmDeleteModalProps) => (
  <AppContext.Consumer>
    {({ actions }) => (
      <Modal
        className="confirm-delete-modal"
        title={title || `Delete ${singularize(itemType)} ${itemTitle}?`}
      >
        <div className="row">
          <Button
            dangerous
            stature="primary"
            label="Delete"
            onClick={() => {
              deleteItem();
              actions.setModal(null);
            }}
          />
          <Button label="Cancel" onClick={() => actions.setModal(null)} />
        </div>
      </Modal>
    )}
  </AppContext.Consumer>
);

export default ConfirmDeleteModal;
