import * as H from 'history';

const setQSParams = (history: H.History<any>, qsParams: any) => {
  const newLocation = history.location;
    const newParams = new URLSearchParams(newLocation.search);

    for (const key in qsParams) {
      newParams.set(key, (qsParams as any)[key]);
    }

    newLocation.search = '?' + newParams.toString();

    history.push(newLocation);
};

export default setQSParams;