import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';
import AppContext from '../../helpers/AppContext';
import { createData } from '../../helpers/crudData';
import AddressField from '../AddressField';
import Button from '../Button';
import Modal from './Modal';

const NewCompanyModal = () => (
  <Modal className="new-company-modal" title="New company">
    {(closeModal: () => void) => (
      <AppContext.Consumer>
        {({ actions }) => (
          <Formik
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Please include a company name.'),
              industries: Yup.string(),
              address: Yup.string(),
            })}
            initialValues={{
              name: '',
              industries: '',
              address: '',
            }}
            onSubmit={async (values, formActions) => {
              const type = 'companies';
              const response = await createData({ type }, values);

              formActions.setSubmitting(false);
              actions.pushHistory(`/${type}/${response._id}`);
              closeModal();
            }}
            render={({
              errors,
              touched,
              isSubmitting,
              handleBlur,
              values,
              submitForm,
              setFieldValue,
            }) => (
              <Form>
                <div className="row medium-margin-bottom">
                  <label htmlFor="name">Name</label>
                  <Field
                    autoFocus
                    type="text"
                    name="name"
                    id="name"
                    className={errors.name && touched.name ? 'has-error' : ''}
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck={false}
                    data-gramm={false}
                  />
                </div>
                <ErrorMessage name="name">
                  {msg => <div className="form-validation-error">{msg}</div>}
                </ErrorMessage>

                <div className="row medium-margin-bottom">
                  <label htmlFor="industries">Industries</label>
                  <Field
                    type="text"
                    name="industries"
                    id="industries"
                    placeholder="Separated by commas"
                    className={
                      errors.industries && touched.industries ? 'has-error' : ''
                    }
                  />
                </div>
                <ErrorMessage name="industries">
                  {msg => <div className="form-validation-error">{msg}</div>}
                </ErrorMessage>

                <div className="row small-margin-bottom">
                  <label htmlFor="address">Address</label>
                </div>
                <div className="row medium-margin-bottom">
                  <AddressField
                    value={values.address}
                    setValue={(value: string) =>
                      setFieldValue('address', value)
                    }
                    id="address"
                    placeholder="Type..."
                    hasError={!!(errors.address && touched.address)}
                    onBlur={handleBlur}
                  />
                </div>
                <ErrorMessage name="address">
                  {msg => <div className="form-validation-error">{msg}</div>}
                </ErrorMessage>

                <div className="row">
                  <Button
                    type="submit"
                    stature="primary"
                    label="Add"
                    icon="plus"
                    disabled={isSubmitting}
                    onClick={submitForm}
                  />
                </div>
              </Form>
            )}
          />
        )}
      </AppContext.Consumer>
    )}
  </Modal>
);

export default NewCompanyModal;
