import Table, { ColumnProps } from 'antd/lib/table';
import 'antd/lib/table/style/index.css';
import moment from 'moment';
import * as React from 'react';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { alphanumericSorter } from '../../helpers/alphanumericSorter';
import AppContext from '../../helpers/AppContext';
import { deleteData } from '../../helpers/crudData';
import { statusOptions } from '../../helpers/dictionaries';
import getPathnameFromAddress from '../../helpers/getPathnameFromAddress';
import getTitle from '../../helpers/getTitle';
import Button from '../Button';
import ConfirmDeleteModal from '../Modal/ConfirmDeleteModal';
import IndexMenu from './IndexMenu';

interface IState {
  selectedRowKeys: string[];
  tableHeight?: number;
}

class Index extends Component<any, IState> {
  public static contextType = AppContext;

  public state: IState = {
    selectedRowKeys: [],
    tableHeight: undefined,
  };

  public componentDidMount = () => {
    this.setTableHeight();

    window.addEventListener('resize', this.setTableHeight, {
      passive: true,
    });
  };

  public componentWillUnmount = () => {
    window.removeEventListener('resize', this.setTableHeight);
  };

  private setTableHeight = () => {
    const indexPane = document.getElementById('index-pane');
    const indexMenu = document.getElementById('index-menu');
    const indexHeader = indexPane
      ? (indexPane
          .getElementsByClassName('ant-table-thead')
          .item(0) as HTMLElement)
      : null;

    if (indexPane && indexMenu && indexHeader) {
      const tableHeight =
        indexPane.offsetHeight -
        indexMenu.offsetHeight -
        indexHeader.offsetHeight;

      this.setState({ tableHeight });
    }
  };

  private deleteItem = async (id: string): Promise<void> => {
    const { data } = this.context.state;
    const { type } = this.context.state.address;
    const { refreshViewData } = this.context.actions;
    const itemAddress = { type, id };

    this.context.actions.setModal(
      <ConfirmDeleteModal
        itemTitle={getTitle(
          itemAddress,
          (data.primary as any[]).find((item: any) => item._id === id)
        )}
        itemType={type}
        deleteItem={async () => {
          const indexInSelected = this.state.selectedRowKeys.findIndex(
            key => key === itemAddress.id
          );

          // Never do deleteData().then(refreshViewData) because the response
          // from calling deleteData() is the item that was deleted, not the
          // primary data that we want to pass to refreshViewData() to refresh
          // with.
          await deleteData(itemAddress);
          await refreshViewData();

          // When we delete a row that was selected, we remove its key from
          // selectedRowKeys state so the counter on the "Delete selected"
          // button is accurate. The table does not call the rowSelection
          // onChange handler when a row is deleted, so we handle it manually.
          if (indexInSelected > -1) {
            const selectedRowKeys = [...this.state.selectedRowKeys];
            selectedRowKeys.splice(indexInSelected, 1);

            this.setState({ selectedRowKeys });
          }
        }}
      />
    );
  };

  private deleteSelectedItems = async (): Promise<void> => {
    const { type } = this.context.state.address;
    const { setModal, refreshViewData } = this.context.actions;
    const { selectedRowKeys } = this.state;

    setModal(
      <ConfirmDeleteModal
        title={`Delete the selected ${type}? (${selectedRowKeys.length})`}
        itemType={type}
        deleteItem={async () => {
          await Promise.all(
            selectedRowKeys.map(id => deleteData({ type, id }))
          );
          await refreshViewData();

          // Selected table rows are no longer selected when they are deleted.
          // However, the table will not call the rowSelection onChange handler,
          // so we have to manually clear the selectedRowKeys state so the
          // "Delete selected" button will get disabled.
          this.setState({ selectedRowKeys: [] });
        }}
      />
    );
  };

  private getFirstCellLink = (value: any, record: any) => (
    <Link
      to={getPathnameFromAddress({
        type: this.context.state.address.type,
        id: record._id,
      })}
    >
      {value}
    </Link>
  );

  private getCellLink = (value: any, record: any) => (
    <Link
      tabIndex={-1}
      to={getPathnameFromAddress({
        type: this.context.state.address.type,
        id: record._id,
      })}
    >
      {value}
    </Link>
  );

  private getTableData = (): any[] => {
    const {
      data,
      address: { type },
    } = this.context.state;

    if (Array.isArray(data.primary) && data.primary.length > 0)
      switch (type) {
        case 'jobs':
          return data.primary.map((record: any) => {
            const sortedEntries = (record.entries as any[]).sort(
              (firstEntry: any, secondEntry: any) =>
                firstEntry.sort < secondEntry.sort ? 1 : -1
            );

            const latestStatusEntry = sortedEntries.find(
              entry => entry.type === 'STATUS'
            );

            return {
              _id: record._id,
              title: record.title,
              posted: record.posted ? moment(record.posted).format('l') : '',
              company: data.titles[record.company],
              status: latestStatusEntry
                ? (statusOptions as any)[latestStatusEntry.status]
                : 'Saved',
              location: record.location,
            };
          });

        case 'companies':
          return data.primary;

        case 'contacts':
          return data.primary.map((record: any) => ({
            ...record,
            primaryCompany: data.titles[record.primaryCompany],
          }));
      }

    return [];
  };

  private getTableColumns = (): ColumnProps<any>[] => {
    const deleteButtonColumn = {
      title: '',
      dataIndex: '_id',
      width: 100,
      render: (id: any) => (
        <Button
          size="small"
          dangerous
          stature="trivial"
          label="Delete"
          onClick={() => this.deleteItem(id)}
          className="delete"
        />
      ),
    };

    switch (this.context.state.address.type) {
      case 'jobs':
        return [
          {
            title: 'Title',
            dataIndex: 'title',
            width: 300,
            render: this.getFirstCellLink,
            defaultSortOrder: 'ascend',
            sorter: (a, b) => alphanumericSorter(a, b, 'title'),
          },
          {
            title: 'Posted',
            dataIndex: 'posted',
            width: 100,
            render: this.getCellLink,
            sorter: (a, b) => alphanumericSorter(a, b, 'posted'),
          },
          {
            title: 'Company',
            dataIndex: 'company',
            width: 200,
            render: this.getCellLink,
            sorter: (a, b) => alphanumericSorter(a, b, 'company'),
          },
          {
            title: 'Status',
            dataIndex: 'status',
            width: 180,
            render: this.getCellLink,
            sorter: (a, b) => alphanumericSorter(a, b, 'status'),
          },
          {
            title: 'Location',
            dataIndex: 'location',
            width: 200,
            render: this.getCellLink,
            sorter: (a, b) => alphanumericSorter(a, b, 'location'),
          },
          deleteButtonColumn,
        ];
      case 'companies':
        return [
          {
            title: 'Company',
            dataIndex: 'name',
            width: 300,
            render: this.getFirstCellLink,
            defaultSortOrder: 'ascend',
            sorter: (a, b) => alphanumericSorter(a, b, 'name'),
          },
          {
            title: 'Industries',
            dataIndex: 'industries',
            width: 400,
            render: this.getCellLink,
            sorter: (a, b) => alphanumericSorter(a, b, 'industries'),
          },
          {
            title: 'Address',
            dataIndex: 'address',
            render: this.getCellLink,
            sorter: (a, b) => alphanumericSorter(a, b, 'address'),
          },
          deleteButtonColumn,
        ];
      case 'contacts':
        return [
          {
            title: 'First name',
            dataIndex: 'name.first',
            width: 175,
            render: this.getFirstCellLink,
            sorter: (a, b) => alphanumericSorter(a, b, 'name.first'),
          },
          {
            title: 'Last name',
            dataIndex: 'name.last',
            width: 175,
            render: this.getCellLink,
            defaultSortOrder: 'ascend',
            sorter: (a, b) => alphanumericSorter(a, b, 'name.last'),
          },
          {
            title: 'Title',
            width: 250,
            dataIndex: 'jobTitle',
            render: this.getCellLink,
            sorter: (a, b) => alphanumericSorter(a, b, 'jobTitle'),
          },
          {
            title: 'Company',
            dataIndex: 'primaryCompany',
            width: 250,
            render: this.getCellLink,
            sorter: (a, b) => alphanumericSorter(a, b, 'primaryCompany'),
          },
          {
            title: 'Address',
            dataIndex: 'address',
            render: this.getCellLink,
            sorter: (a, b) => alphanumericSorter(a, b, 'address'),
          },
          deleteButtonColumn,
        ];
    }

    return [];
  };

  public render = () => {
    const columns = this.getTableColumns();
    const dataSource = this.getTableData();

    return (
      <section className="content" id="index-pane">
        <IndexMenu
          selectedRowCount={this.state.selectedRowKeys.length}
          deleteSelectedItems={() => {
            if (this.state.selectedRowKeys.length === 1)
              this.deleteItem(this.state.selectedRowKeys[0]);
            else this.deleteSelectedItems();
          }}
        />
        <Table
          scroll={{ y: this.state.tableHeight }}
          rowKey="_id"
          columns={columns}
          dataSource={dataSource}
          sortDirections={['ascend', 'descend']}
          pagination={false}
          rowSelection={{
            onChange: selectedRowKeys => {
              this.setState({
                // If the key still exists as an item's _id, include it in
                // selectedRowKeys state. We check this so that the "Delete
                // selected" button's counter will not count items that have
                // since been deleted after being previously selected.
                selectedRowKeys: (selectedRowKeys as any[]).reduce(
                  (accumulatedKeys, key) => {
                    if (dataSource.findIndex(item => item._id === key) > -1)
                      return accumulatedKeys.concat(`${key}`);

                    return accumulatedKeys;
                  },
                  []
                ),
              });
            },
          }}
          indentSize={0}
        />
      </section>
    );
  };
}

export default Index;
