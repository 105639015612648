import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';
import AppContext from '../../helpers/AppContext';
import { updateData } from '../../helpers/crudData';
import { linkSchema } from '../../helpers/validationSchemas';
import AddressField from '../AddressField';
import Button from '../Button';
import DateTime from '../DateTime';
import FieldArrayItem from '../FieldArrayItem';
import FieldArrayItemModal from '../Modal/FieldArrayItemModal';
import WarnOfUnsavedChanges from '../WarnOfUnsavedChanges';

interface IProps {
  deleteItem: () => Promise<void>;
  refresh: (...args: any[]) => any;
}

interface IState {
  dateTimeShouldRevertToInitVal: boolean;
}

export default class CompanySidebar extends React.Component<IProps, IState> {
  public static contextType = AppContext;

  public state: IState = {
    dateTimeShouldRevertToInitVal: false,
  };

  public render() {
    // Use destructuring and a self-calling function to pick out specific
    // properties from the primary data to use as Formik's initial values.
    const initialVals = (({
      _id,
      name,
      industries,
      saved,
      links,
      phone,
      email,
      address,
      notes,
    }) => ({
      _id,
      name,
      industries,
      saved,
      links,
      phone,
      email,
      address,
      notes,
    }))(this.context.state.data.primary);

    const validationSchema = Yup.object().shape({
      name: Yup.string().required('Please include company name.'),
      industries: Yup.string(),
      saved: Yup.date()
        .typeError('Saved date must be in a valid format.')
        .required('Please indicate the date saved.'),
      links: Yup.array().of(linkSchema),
      phone: Yup.string(),
      email: Yup.string().email('Email must be in a valid format.'),
      address: Yup.string(),
      notes: Yup.string(),
    });

    return (
      <section className="item-sidebar">
        <AppContext.Consumer>
          {({ actions: { setModal } }) => (
            <Formik
              initialValues={initialVals}
              validationSchema={validationSchema}
              onSubmit={(values, formActions) => {
                updateData({ type: 'companies', id: values._id }, values)
                  .then(this.props.refresh)
                  .then(formActions.resetForm)
                  .then(() => formActions.setSubmitting(false));
                this.setState({ dateTimeShouldRevertToInitVal: true });
              }}
              onReset={() =>
                this.setState({ dateTimeShouldRevertToInitVal: true })
              }
              render={({
                errors,
                touched,
                isSubmitting,
                handleChange,
                handleBlur,
                values,
                setFieldValue,
                setFieldTouched,
                dirty,
                initialValues,
                handleReset,
              }) => (
                <Form>
                  <WarnOfUnsavedChanges when={dirty} />
                  <div className="row medium-margin-bottom">
                    <Field
                      type="text"
                      name="name"
                      autoComplete="off"
                      placeholder="Name"
                      aria-label="Name"
                      id="name"
                      className={
                        'title' +
                        (errors.name && touched.name ? ' has-error' : '')
                      }
                    />

                    <Button
                      type="submit"
                      label="Save"
                      stature="primary"
                      disabled={isSubmitting || !dirty}
                    />
                    <Button
                      label="Cancel"
                      onClick={handleReset}
                      disabled={isSubmitting || !dirty}
                    />
                  </div>
                  <ErrorMessage name="name">
                    {msg => <div className="form-validation-error">{msg}</div>}
                  </ErrorMessage>

                  <div className="row medium-margin-bottom">
                    <label htmlFor="industries">Industries</label>
                    <Field
                      type="text"
                      name="industries"
                      id="industries"
                      placeholder="Separated by commas"
                      className={
                        errors.industries && touched.industries
                          ? 'has-error'
                          : ''
                      }
                    />
                  </div>
                  <ErrorMessage name="industries">
                    {msg => <div className="form-validation-error">{msg}</div>}
                  </ErrorMessage>

                  <div className="row medium-margin-bottom">
                    <label htmlFor="saved">Saved</label>
                    <DateTime
                      name="saved"
                      inputId="saved"
                      type="DATE"
                      initialValue={initialValues.saved}
                      defaultValue={values.saved}
                      shouldRevertToInitVal={
                        this.state.dateTimeShouldRevertToInitVal
                      }
                      didRevertToInitVal={() =>
                        this.setState({ dateTimeShouldRevertToInitVal: false })
                      }
                      hasError={!!(errors.saved && touched.saved)}
                      setValue={setFieldValue}
                      setTouched={setFieldTouched}
                    />
                    <Button
                      label="Delete company"
                      size="small"
                      dangerous
                      onClick={this.props.deleteItem}
                    />
                  </div>
                  <ErrorMessage name="saved">
                    {msg => <div className="form-validation-error">{msg}</div>}
                  </ErrorMessage>

                  <div className="row small-margin-bottom">
                    <label>Links</label>
                  </div>
                  <FieldArray
                    name="links"
                    render={arrayHelpers => (
                      <div className="field-array-items medium-margin-bottom">
                        {values.links.map((link: any, i: number) => (
                          <FieldArrayItem
                            key={i}
                            label={link.label}
                            href={link.value}
                            value={link.value}
                            valueType="link"
                            valueFieldLabel="URL"
                            valueNameForHeading="link"
                            setValue={(value: string, label?: string) => {
                              setFieldValue(`links[${i}].value`, value);
                              setFieldValue(`links[${i}].label`, label);
                            }}
                            deleteItem={() => arrayHelpers.remove(i)}
                            validationSchema={linkSchema}
                          />
                        ))}

                        <Button
                          size="small"
                          stature="trivial"
                          icon="plus"
                          label="Add link"
                          className="add"
                          onClick={() =>
                            setModal(
                              <FieldArrayItemModal
                                valueType="link"
                                valueFieldLabel="URL"
                                valueNameForHeading="link"
                                validationSchema={linkSchema}
                                isNew
                                addItem={(value: string, label?: string) =>
                                  arrayHelpers.insert(values.links.length, {
                                    value,
                                    label,
                                  })
                                }
                              />
                            )
                          }
                        />
                      </div>
                    )}
                  />

                  <div className="row medium-margin-bottom">
                    <label htmlFor="email">Email</label>
                    <Field
                      type="email"
                      name="email"
                      id="email"
                      placeholder="name@example.com"
                      className={
                        errors.email && touched.email ? 'has-error' : ''
                      }
                    />
                  </div>
                  <ErrorMessage name="email">
                    {msg => <div className="form-validation-error">{msg}</div>}
                  </ErrorMessage>

                  <div className="row medium-margin-bottom">
                    <label htmlFor="phone">Phone</label>
                    <Field
                      type="tel"
                      name="phone"
                      id="phone"
                      placeholder="(555) 555-1234"
                      className={
                        errors.phone && touched.phone ? 'has-error' : ''
                      }
                    />
                  </div>
                  <ErrorMessage name="phone">
                    {msg => <div className="form-validation-error">{msg}</div>}
                  </ErrorMessage>

                  <div className="row small-margin-bottom">
                    <label htmlFor="address">Address</label>
                  </div>
                  <div className="row medium-margin-bottom">
                    <AddressField
                      value={values.address}
                      setValue={(value: string) =>
                        setFieldValue('address', value)
                      }
                      id="address"
                      placeholder="Type..."
                      hasError={!!(errors.address && touched.address)}
                      onBlur={handleBlur}
                    />
                  </div>
                  <ErrorMessage name="address">
                    {msg => <div className="form-validation-error">{msg}</div>}
                  </ErrorMessage>

                  <div className="row small-margin-bottom">
                    <label htmlFor="notes">Notes</label>
                  </div>
                  <textarea
                    id="notes"
                    className="medium-margin-bottom"
                    name="notes"
                    value={values.notes}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage name="notes">
                    {msg => <div className="form-validation-error">{msg}</div>}
                  </ErrorMessage>
                </Form>
              )}
            />
          )}
        </AppContext.Consumer>
      </section>
    );
  }
}
