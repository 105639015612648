import * as H from 'history';

const setAllQSParams = (history: H.History<any>, qsParams: any) => {
  const newLocation = history.location;
  let newParams = '?';

  for (const key in qsParams) {
    newParams += `${key}=${qsParams[key]}`;
  }

  newLocation.search = newParams;

  history.push(newLocation);
};

export default setAllQSParams;
