import moment from 'moment';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { alphanumericSorter } from '../helpers/alphanumericSorter';
import AppContext from '../helpers/AppContext';
import { deleteData, readData, updateData } from '../helpers/crudData';
import { statusOptions } from '../helpers/dictionaries';
import getTitle from '../helpers/getTitle';
import { IItemAddress } from '../helpers/interfaces';
import ChatLogEntry from './Entry/ChatLogEntry';
import EventEntry from './Entry/EventEntry';
import PostEntry from './Entry/PostEntry';
import StatusEntry from './Entry/StatusEntry';
import TaskEntry from './Entry/TaskEntry';
import EntryComposer from './EntryComposer';
import CompanySidebar from './ItemSidebar/CompanySidebar';
import ContactSidebar from './ItemSidebar/ContactSidebar';
import JobSidebar from './ItemSidebar/JobSidebar';
import ItemTable from './ItemTable';
import ConfirmDeleteModal from './Modal/ConfirmDeleteModal';

export default class Item extends React.Component<any> {
  public static contextType = AppContext;

  private deleteItem = async (): Promise<void> => {
    const { state, actions } = this.context;
    const { type } = state.address;
    const itemAddress = { type, id: state.data.primary._id };

    actions.setModal(
      <ConfirmDeleteModal
        itemTitle={getTitle(itemAddress, state.data.primary)}
        itemType={type}
        deleteItem={() =>
          deleteData(itemAddress).then(() => actions.pushHistory('/' + type))
        }
      />
    );
  };

  // When detaching or attaching items, we only send the updated properties of
  // the field that we're updating so that we don't lose any changes that the
  // user may have made in another tab or instance of EmployAmp before this
  // instance is refreshed.

  private detachInternally = async (selectedResourceAddress: IItemAddress) => {
    const { address } = this.context.state;
    const data = await readData(address);

    const updatedData = {
      _id: address._id,
      [selectedResourceAddress.type]: [...data[selectedResourceAddress.type]],
    };

    const indexToRemove = updatedData[selectedResourceAddress.type].indexOf(
      selectedResourceAddress.id
    );
    if (indexToRemove > -1)
      updatedData[selectedResourceAddress.type].splice(indexToRemove, 1);

    updateData(address, updatedData).then(this.context.actions.refreshViewData);
  };

  private detachExternally = async (
    selectedResourceAddress: IItemAddress
  ): Promise<any> => {
    const { address } = this.context.state;
    const selectedResource = await readData(selectedResourceAddress);

    const updatedData = {
      _id: selectedResource._id,
      [address.type]: [...selectedResource[address.type]],
    };

    const indexToRemove = updatedData[address.type].indexOf(address.id);
    if (indexToRemove > -1) updatedData[address.type].splice(indexToRemove, 1);

    // Use an arrow function because we want to call refreshViewData without
    // arguments. We don't want to pass the response that came back from
    // updating the external document because this is not what we want to
    // refresh the view data with.
    updateData(selectedResourceAddress, updatedData).then(() =>
      this.context.actions.refreshViewData()
    );
  };

  private attachInternally = async (selectedResourceAddress: IItemAddress) => {
    const { address } = this.context.state;
    const data = await readData(address);

    const updatedData = {
      _id: address._id,
      [selectedResourceAddress.type]: [
        ...data[selectedResourceAddress.type],
        selectedResourceAddress.id,
      ],
    };

    updateData(address, updatedData).then(this.context.actions.refreshViewData);
  };

  private attachExternally = async (
    selectedResourceAddress: IItemAddress
  ): Promise<any> => {
    const { address } = this.context.state;
    const selectedResource = await readData(selectedResourceAddress);

    const updatedData = {
      _id: selectedResource._id,
      [address.type]: [...selectedResource[address.type]],
    };

    updatedData[address.type].push(address.id);

    // Use an arrow function because we want to call refreshViewData without
    // arguments. We don't want to pass the response that came back from
    // updating the external document because this is not what we want to
    // refresh the view data with.
    updateData(selectedResourceAddress, updatedData).then(() =>
      this.context.actions.refreshViewData()
    );
  };

  public render() {
    const { refreshViewData } = this.context.actions;

    const jobTableData = this.context.state.data.jobs.map((job: any) => {
      const sortedEntries = (job.entries as any[]).sort(
        (firstEntry: any, secondEntry: any) =>
          firstEntry.sort < secondEntry.sort ? 1 : -1
      );

      const latestStatusEntry = sortedEntries.find(
        entry => entry.type === 'STATUS'
      );

      const status = latestStatusEntry
        ? (statusOptions as any)[latestStatusEntry.status]
        : 'Saved';

      return {
        _id: job._id,
        posted: job.posted ? moment(job.posted).format('l') : '',
        title: job.title,
        company: this.context.state.data.titles[job.company],
        status,
      };
    });

    return (
      <AppContext.Consumer>
        {context => (
          <Switch>
            <Route
              path="/jobs"
              render={() => (
                <>
                  <JobSidebar
                    deleteItem={this.deleteItem}
                    refresh={refreshViewData}
                  />
                  <section className="contentWithSidebar">
                    <div className="timeline">
                      <EntryComposer />
                      {context.state.data.primary &&
                        Array.isArray(context.state.data.primary.entries) &&
                        context.state.data.primary.entries
                          .sort((firstEntry: any, secondEntry: any) =>
                            firstEntry.sort < secondEntry.sort ? 1 : -1
                          )
                          .map((entry: any) => {
                            const common = { key: entry._id, data: entry };

                            switch (entry.type) {
                              case 'STATUS':
                                return <StatusEntry {...common} />;
                              case 'POST':
                                return <PostEntry {...common} />;
                              case 'TASK':
                                return <TaskEntry {...common} />;
                              case 'EVENT':
                                return <EventEntry {...common} />;
                              case 'CHAT_LOG':
                                return <ChatLogEntry {...common} />;
                            }
                          })}
                      <div className="spacingBoi" />
                    </div>
                  </section>
                </>
              )}
            />
            <Route
              path="/companies"
              render={() => (
                <>
                  <CompanySidebar
                    deleteItem={this.deleteItem}
                    refresh={refreshViewData}
                  />
                  <section className="contentWithSidebar">
                    <div className="timeline">
                      <ItemTable
                        type="jobs"
                        data={jobTableData}
                        columns={[
                          {
                            title: 'Posted',
                            dataIndex: 'posted',
                            width: 300,
                            defaultSortOrder: 'ascend',
                            sorter: (a: any, b: any) =>
                              alphanumericSorter(a, b, 'posted'),
                          },
                          {
                            title: 'Title',
                            dataIndex: 'title',
                            width: 400,
                            sorter: (a: any, b: any) =>
                              alphanumericSorter(a, b, 'title'),
                          },
                          {
                            title: 'Status',
                            dataIndex: 'status',
                            width: 400,
                            sorter: (a: any, b: any) =>
                              alphanumericSorter(a, b, 'status'),
                          },
                        ]}
                      />
                      <ItemTable
                        type="contacts"
                        attachItem={this.attachExternally}
                        detachItem={this.detachExternally}
                        data={context.state.data.contacts}
                        columns={[
                          {
                            title: 'First',
                            dataIndex: 'name.first',
                            width: 300,
                            defaultSortOrder: 'ascend',
                            sorter: (a: any, b: any) =>
                              alphanumericSorter(a, b, 'name.first'),
                          },
                          {
                            title: 'Last',
                            dataIndex: 'name.last',
                            width: 300,
                            sorter: (a: any, b: any) =>
                              alphanumericSorter(a, b, 'name.last'),
                          },
                          {
                            title: 'Title',
                            dataIndex: 'jobTitle',
                            width: 400,
                            sorter: (a: any, b: any) =>
                              alphanumericSorter(a, b, 'jobTitle'),
                          },
                        ]}
                      />
                      <div className="spacingBoi" />
                    </div>
                  </section>
                </>
              )}
            />
            <Route
              path="/contacts"
              render={() => (
                <>
                  <ContactSidebar
                    deleteItem={this.deleteItem}
                    refresh={refreshViewData}
                  />
                  <section className="contentWithSidebar">
                    <div className="timeline">
                      {/* <CollapsibleTable
                        type="entries"
                        data={[]}
                        mergeUpdatedData={updatedData => {
                          return;
                        }}
                      /> */}
                      <ItemTable
                        type="jobs"
                        attachItem={this.attachInternally}
                        detachItem={this.detachInternally}
                        data={jobTableData}
                        columns={[
                          {
                            title: 'Posted',
                            dataIndex: 'posted',
                            width: 300,
                            defaultSortOrder: 'ascend',
                            sorter: (a: any, b: any) =>
                              alphanumericSorter(a, b, 'posted'),
                          },
                          {
                            title: 'Title',
                            dataIndex: 'title',
                            width: 400,
                            sorter: (a: any, b: any) =>
                              alphanumericSorter(a, b, 'title'),
                          },
                          {
                            title: 'Company',
                            dataIndex: 'company',
                            width: 400,
                            sorter: (a: any, b: any) =>
                              alphanumericSorter(a, b, 'company'),
                          },
                          {
                            title: 'Status',
                            dataIndex: 'status',
                            width: 400,
                            sorter: (a: any, b: any) =>
                              alphanumericSorter(a, b, 'status'),
                          },
                        ]}
                      />
                      <ItemTable
                        type="companies"
                        attachItem={this.attachInternally}
                        detachItem={this.detachInternally}
                        data={context.state.data.companies}
                        columns={[
                          {
                            title: 'Company',
                            dataIndex: 'name',
                            width: 300,
                            defaultSortOrder: 'ascend',
                            sorter: (a: any, b: any) =>
                              alphanumericSorter(a, b, 'name'),
                          },
                          {
                            title: 'Industries',
                            dataIndex: 'industries',
                            width: 400,
                            sorter: (a: any, b: any) =>
                              alphanumericSorter(a, b, 'industries'),
                          },
                        ]}
                      />
                      <div className="spacingBoi" />
                    </div>
                  </section>
                </>
              )}
            />
          </Switch>
        )}
      </AppContext.Consumer>
    );
  }
}
