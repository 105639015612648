import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';
import AppContext from '../../helpers/AppContext';
import { createData } from '../../helpers/crudData';
import Button from '../Button';
import Modal from './Modal';

const NewContactModal = () => (
  <Modal className="new-contact-modal" title="New contact">
    {(closeModal: () => void) => (
      <AppContext.Consumer>
        {({ actions }) => (
          <Formik
            validationSchema={Yup.object().shape({
              firstName: Yup.string().required('Please include a first name.'),
              lastName: Yup.string().required('Please include a last name.'),
              jobTitle: Yup.string(),
            })}
            initialValues={{
              firstName: '',
              lastName: '',
              jobTitle: '',
            }}
            onSubmit={async (values, formActions) => {
              const type = 'contacts';
              const body = {
                name: { first: values.firstName, last: values.lastName },
                jobTitle: values.jobTitle,
              };

              const response = await createData({ type }, body);

              formActions.setSubmitting(false);
              actions.pushHistory(`/${type}/${response._id}`);
              closeModal();
            }}
            render={({ errors, touched, isSubmitting, submitForm }) => (
              <Form>
                <div className="row medium-margin-bottom">
                  <label htmlFor="firstName">First name</label>
                  <Field
                    autoFocus
                    type="text"
                    name="firstName"
                    id="firstName"
                    className={
                      errors.firstName && touched.firstName ? 'has-error' : ''
                    }
                    autoCorrect="off"
                    spellCheck={false}
                    data-gramm={false}
                  />
                </div>
                <ErrorMessage name="firstName">
                  {msg => <div className="form-validation-error">{msg}</div>}
                </ErrorMessage>

                <div className="row medium-margin-bottom">
                  <label htmlFor="lastName">Last name</label>
                  <Field
                    type="text"
                    name="lastName"
                    id="lastName"
                    className={
                      errors.lastName && touched.lastName ? 'has-error' : ''
                    }
                    autoCorrect="off"
                    spellCheck={false}
                    data-gramm={false}
                  />
                </div>
                <ErrorMessage name="lastName">
                  {msg => <div className="form-validation-error">{msg}</div>}
                </ErrorMessage>

                <div className="row medium-margin-bottom">
                  <label htmlFor="jobTitle">Job title</label>
                  <Field
                    type="text"
                    name="jobTitle"
                    id="jobTitle"
                    className={
                      errors.jobTitle && touched.jobTitle ? 'has-error' : ''
                    }
                  />
                </div>
                <ErrorMessage name="jobTitle">
                  {msg => <div className="form-validation-error">{msg}</div>}
                </ErrorMessage>

                <div className="row">
                  <Button
                    type="submit"
                    stature="primary"
                    label="Add"
                    icon="plus"
                    disabled={isSubmitting}
                    onClick={submitForm}
                  />
                </div>
              </Form>
            )}
          />
        )}
      </AppContext.Consumer>
    )}
  </Modal>
);

export default NewContactModal;
