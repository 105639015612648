import * as React from 'react';
import * as ReactModal from 'react-modal';
import AppContext from '../../helpers/AppContext';

interface IProps {
  title: string;
  beforeClose?: () => void;
  className?: string;
  children: any;
}

export default class Modal extends React.Component<IProps> {
  public static contextType = AppContext;

  private handleRequestClose = () => {
    if (this.props.beforeClose) this.props.beforeClose();
    this.context.actions.setModal(null);
  };

  public render() {
    const { title, className, children } = this.props;

    return (
      <ReactModal
        appElement={document.getElementById('root') || undefined}
        isOpen={true}
        className={'modal' + (className ? ' ' + className : '')}
        overlayClassName="overlay"
        aria={{
          labelledby: 'modal-title',
        }}
        onRequestClose={this.handleRequestClose}
      >
        <header>
          <h1 id="modal-title">{title}</h1>
          <button type="button" onClick={this.handleRequestClose}>
            Close
          </button>
        </header>
        <div className="content">
          {typeof children === 'function'
            ? (children as any)(this.handleRequestClose)
            : children}
        </div>
      </ReactModal>
    );
  }
}
