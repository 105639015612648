import { readData } from './crudData';
import getTitleDictionary from './getTitleDictionary';
import { IAddress, IItemAddress } from './interfaces';
import nonAddressRoutes from './nonAddressRoutes';

const getViewData = async (
  address: IAddress,
  prefetchedPrimary?: any
): Promise<any> => {
  const data: any = {
    primary: null,
    titles: {},
    jobs: [],
    entries: [],
    companies: [],
    contacts: [],
  };

  if (!address || !address.type || nonAddressRoutes.includes(address.type)) {
    return data;
  }

  let addressesOfTitles: IItemAddress[] = [];

  if (address.id) {
    if (prefetchedPrimary && !Array.isArray(prefetchedPrimary))
      data.primary = prefetchedPrimary;
    else data.primary = await readData(address);

    switch (address.type) {
      case 'jobs':
        addressesOfTitles = addressesOfTitles.concat({
          type: 'companies',
          id: data.primary.company,
        });
        break;
      case 'companies':
        data.jobs = await readData({ type: 'jobs' }, [
          `company=${data.primary._id}`,
        ]);
        data.contacts = await readData({ type: 'contacts' }, [
          `company=${data.primary._id}`,
        ]);
        break;
      case 'contacts':
        if (Array.isArray(data.primary.jobs) && data.primary.jobs.length > 0) {
          data.jobs = await readData({ type: 'jobs' }, [
            `contact=${data.primary._id}`,
          ]);
        }
        if (
          Array.isArray(data.primary.companies) &&
          data.primary.companies.length > 0
        ) {
          data.companies = await readData({ type: 'companies' }, [
            `contact=${data.primary._id}`,
          ]);
        }
        addressesOfTitles = addressesOfTitles.concat(
          {
            type: 'companies',
            id: data.primary.primaryCompany,
          },
          data.jobs.map((job: any) => ({ type: 'companies', id: job.company }))
        );
    }
  } else {
    if (prefetchedPrimary && Array.isArray(prefetchedPrimary))
      data.primary = prefetchedPrimary;
    else data.primary = await readData(address);

    switch (address.type) {
      case 'jobs':
        const companyAddresses = data.primary.map(
          (job: any): IItemAddress => ({ type: 'companies', id: job.company })
        );
        addressesOfTitles = addressesOfTitles.concat(companyAddresses);
        break;
      case 'companies':
        break;
      case 'contacts':
        const primaryCompanyAddresses = data.primary
          .map(
            (contact: any): IItemAddress => ({
              type: 'companies',
              id: contact.primaryCompany,
            })
          )
          .filter(
            (companyAddress: IItemAddress) => companyAddress.id !== undefined
          );

        addressesOfTitles = addressesOfTitles.concat(primaryCompanyAddresses);
    }
  }

  data.titles = await getTitleDictionary(addressesOfTitles);

  return data;
};

export default getViewData;
